// ロジック系ブロックリストの定義
export const block_controls_if = {
  kind: "block",
  type: "controls_if",
};
export const block_logic_compare = {
  kind: "block",
  type: "logic_compare",
};
export const block_logic_operation = {
  kind: "block",
  type: "logic_operation",
};
export const block_logic_negate = {
  kind: "block",
  type: "logic_negate",
};
export const block_logic_boolean = {
  kind: "block",
  type: "logic_boolean",
};
export const block_logic_null = {
  kind: "block",
  type: "logic_null",
};
export const block_logic_ternary = {
  kind: "block",
  type: "logic_ternary",
};

export const category_logic_fullset = {
  kind: "category",
  name: "%{BKY_CATEGORY_LOGIC}",
  colour: "%{BKY_LOGIC_HUE}",
  contents: [
    block_controls_if,
    block_logic_compare,
    block_logic_operation,
    block_logic_negate,
    block_logic_boolean,
    block_logic_null,
    block_logic_ternary,
  ],
};
