import Blockly from "blockly";
import { javascriptGenerator } from "blockly/javascript";

Blockly.Blocks["set_value"] = {
  init: function () {
    // トランスレーションの正しいやり方が分からなかったので、テンプレートリテラルで対応
    // "%{SPREADSHEET_SET_VALUE}" っていう書き方で動くはずなんだけど...
    this.appendDummyInput().appendField(`${Blockly.Msg.SPREADSHEET_SET_VALUE}`);
    this.appendValueInput("col")
      .appendField(`${Blockly.Msg.SPREADSHEET_COL}`)
      .setCheck(null);
    this.appendValueInput("row").appendField(`${Blockly.Msg.SPREADSHEET_ROW}`);
    this.appendValueInput("value")
      .setCheck(null)
      .appendField(`${Blockly.Msg.SPREADSHEET_VALUE}`);
    this.setInputsInline(false);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(310);
  },
};

javascriptGenerator["set_value"] = function (block) {
  let col = javascriptGenerator.valueToCode(
    block,
    "col",
    javascriptGenerator.ORDER_ATOMIC
  );
  let row = javascriptGenerator.valueToCode(
    block,
    "row",
    javascriptGenerator.ORDER_ATOMIC
  );
  let value = javascriptGenerator.valueToCode(
    block,
    "value",
    javascriptGenerator.ORDER_ATOMIC
  );

  let code = `setValue(${String(col)}, ${String(row)}, ${String(value)});\n`;
  return code;
};

Blockly.Blocks["set_value_s"] = {
  init: function () {
    this.appendDummyInput()
      .appendField(`${Blockly.Msg.SPREADSHEET_SET_VALUE}`)
      .appendField(new Blockly.FieldTextInput("A1"), "colrow");
    this.appendValueInput("value").setCheck(null);
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(310);
    this.setTooltip("");
    this.setHelpUrl("");
  },
};

javascriptGenerator["set_value_s"] = function (block) {
  let colrow = block.getFieldValue("colrow");
  let value = javascriptGenerator.valueToCode(
    block,
    "value",
    javascriptGenerator.ORDER_ATOMIC
  );

  let code = `setValue('${colrow.slice(0, 1)}', ${colrow.slice(1)}, ${String(
    value
  )});\n`;
  return code;
};

Blockly.Blocks["get_value"] = {
  init: function () {
    this.appendDummyInput().appendField(`${Blockly.Msg.SPREADSHEET_GET_VALUE}`);
    this.appendValueInput("col").appendField(`${Blockly.Msg.SPREADSHEET_COL}`);
    this.appendValueInput("row").appendField(`${Blockly.Msg.SPREADSHEET_ROW}`);
    this.setInputsInline(true);
    this.setOutput(true);
    this.setColour(310);
  },
};

javascriptGenerator["get_value"] = function (block) {
  let col = javascriptGenerator.valueToCode(
    block,
    "col",
    javascriptGenerator.ORDER_ATOMIC
  );
  let row = javascriptGenerator.valueToCode(
    block,
    "row",
    javascriptGenerator.ORDER_ATOMIC
  );
  let code = `getValue(${String(col)}, ${String(row)})`;

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, javascriptGenerator.ORDER_NONE];
};

Blockly.Blocks["get_value_s"] = {
  init: function () {
    this.appendDummyInput()
      .appendField()
      .appendField(`${Blockly.Msg.SPREADSHEET_GET_VALUE}`)
      .appendField(new Blockly.FieldTextInput("A1"), "colrow");
    this.setOutput(true);
    this.setColour(310);
    this.setTooltip("");
    this.setHelpUrl("");
  },
};

javascriptGenerator["get_value_s"] = function (block) {
  let colrow = block.getFieldValue("colrow");

  let code = `getValue('${colrow.slice(0, 1)}', ${colrow.slice(1)})`;

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, javascriptGenerator.ORDER_NONE];
};

Blockly.Blocks["get_line_count"] = {
  init: function () {
    this.appendDummyInput().appendField(
      `${Blockly.Msg.SPREADSHEET_GET_FINAL_LINE}`
    );
    this.appendValueInput("col").appendField(`${Blockly.Msg.SPREADSHEET_COL}`);
    this.setInputsInline(true);
    this.setOutput(true, "Number");
    this.setColour(310);
  },
};

javascriptGenerator["get_line_count"] = function (block) {
  let col = javascriptGenerator.valueToCode(
    block,
    "col",
    javascriptGenerator.ORDER_ATOMIC
  );

  let code = `getLineCount(${String(col)})`;

  return [code, javascriptGenerator.ORDER_NONE];
};

Blockly.Blocks["log"] = {
  init: function () {
    this.appendDummyInput().appendField("Log");
    this.appendValueInput("Data").setCheck(null);
    this.setOutput(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
  },
};

javascriptGenerator["log"] = function (block) {
  let Data = javascriptGenerator.valueToCode(
    block,
    "Data",
    javascriptGenerator.ORDER_ATOMIC
  );
  // TODO: Assemble JavaScript into code variable.
  let code = `log(${Data})`;
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, javascriptGenerator.ORDER_NONE];
};

Blockly.Blocks["text_atoi"] = {
  init: function () {
    this.appendDummyInput().appendField("文字を数値に変換:");
    this.appendValueInput("value").setCheck("String");
    this.setInputsInline(true);
    this.setOutput(true, "Number");
    this.setColour('%{BKY_MATH_HUE}');
    this.setTooltip("");
    this.setHelpUrl("");
  },
};

javascriptGenerator["text_atoi"] = function (block) {
  let value = javascriptGenerator.valueToCode(
    block,
    "value",
    javascriptGenerator.ORDER_ATOMIC
  );

  let code = `AtoI(${String(value)})`;

  return [code, javascriptGenerator.ORDER_NONE];
};


Blockly.Blocks["text_itoa"] = {
  init: function () {
    this.appendDummyInput().appendField("数値を文字に変換:");
    this.appendValueInput("value").setCheck("Number");
    this.setInputsInline(true);
    this.setOutput(true, "String");
    this.setColour('%{BKY_TEXTS_HUE}');
    this.setTooltip("");
    this.setHelpUrl("");
  },
};

javascriptGenerator["text_itoa"] = function (block) {
  let value = javascriptGenerator.valueToCode(
    block,
    "value",
    javascriptGenerator.ORDER_ATOMIC
  );

  let code = `ItoA(${value})`;

  return [code, javascriptGenerator.ORDER_NONE];
};

export const OriginalBlockTranslation = {
  ja: {
    SPREADSHEET_GET_VALUE: "表から値を取得",
    SPREADSHEET_SET_VALUE: "表に値を書込む",
    SPREADSHEET_GET_FINAL_LINE: "行数を所得する",
    SPREADSHEET_COL: "列",
    SPREADSHEET_ROW: "行",
    SPREADSHEET_VALUE: "値",
  },
  en: {
    SPREADSHEET_GET_VALUE: "Get value from spreadsheet",
    SPREADSHEET_SET_VALUE: "Set value to spreadsheet",
    SPREADSHEET_GET_FINAL_LINE: "Get Number of lines",
    SPREADSHEET_COL: "Column",
    SPREADSHEET_ROW: "Row",
    SPREADSHEET_VALUE: "Value",
  },
  "zh-hans": {
    SPREADSHEET_GET_VALUE: "Get value from spreadsheet",
    SPREADSHEET_SET_VALUE: "Set value to spreadsheet",
    SPREADSHEET_GET_FINAL_LINE: "Get Number of lines",
    SPREADSHEET_COL: "列",
    SPREADSHEET_ROW: "行",
    SPREADSHEET_VALUE: "値",
  },
  "zh-hant": {
    SPREADSHEET_GET_VALUE: "Get value from spreadsheet",
    SPREADSHEET_SET_VALUE: "Set value to spreadsheet",
    SPREADSHEET_GET_FINAL_LINE: "Get Number of lines",
    SPREADSHEET_COL: "列",
    SPREADSHEET_ROW: "行",
    SPREADSHEET_VALUE: "値",
  },
};
