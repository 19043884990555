import q1 from "./string_manipulation_1.json";
import q2 from "./string_manipulation_2.json";
import q3 from "./string_manipulation_3.json";
import q4 from "./string_manipulation_4.json";
import q5 from "./string_manipulation_5.json";
import q6 from "./string_manipulation_6.json";
import q7 from "./string_manipulation_7.json";
import q8 from "./string_manipulation_8.json";
import q9 from "./string_manipulation_9.json";
import q10 from "./string_manipulation_10.json";

export default [q1, q2, q3, q4, q5, q6, q7, q8, q9, q10];
