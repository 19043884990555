<template>
  <header class="l-header" id="top" v-if="renderComponent">
    <div class="l-header-container">
      <div class="l-header-column">
        <img class="l-header-logo" src="../assets/logo_color.png" />
        <div class="c-nav-gnav-wrap">
          <nav class="c-nav-gnav">
            <ul class="c-nav-gnav-list">
              <li class="c-nav-gnav-list-item">
                <a v-if="isCourseSelect" @click="backJump">コース選択</a>
                <router-link v-else :to="`/problemselect/${organizationID}`">
                  コース選択
                </router-link>
              </li>

              <li
                class="c-nav-gnav-list-item"
                v-for="levelData in levelList"
                :key="levelData.levelID"
                v-bind:class="{
                  'c-nav-gnav-list-levels': currentLevelID != levelData.levelID,
                }"
              >
                <router-link
                  class="c-nav-dropdown-link"
                  :to="`/qa/${organizationID}/${courseID}/${levelData.levelID}/${levelData.firstProblemID}`"
                  @click="clickProblemChange(`${levelData.levelID}`)"
                >
                  <span class="c-nav-gnav-list-item-label">
                    Lv.{{ levelData.levelNumber }}
                  </span>
                  <span class="c-nav-gnav-list-item-title">
                    {{ levelData.levelName }}
                  </span>
                </router-link>
                <ul class="c-nav-dropdown">
                  <li
                    class="c-nav-dropdown-item"
                    v-bind:class="{ finished: isClear[problemList.problemID] }"
                    v-for="(problemList, index) in levelData.problemList"
                    :key="problemList.problemID"
                  >
                    <router-link
                      class="c-btn-icon"
                      :to="`/qa/${organizationID}/${courseID}/${levelData.levelID}/${problemList.problemID}`"
                      @click="clickProblemChange(`${levelData.levelID}`)"
                    >
                      <span
                        class="material-icons c-btn-icon-number"
                        v-if="isClear[problemList.problemID]"
                      >
                        done
                      </span>
                      <span class="c-btn-icon-number" v-else>
                        {{ index + 1 }}
                      </span>

                      <span class="c-btn-icon-txt">
                        {{ problemList.problemName }}
                      </span>
                    </router-link>
                  </li>
                </ul>
              </li>

              <li class="c-nav-gnav-list-item c-nav-gnav-list-item-setting">
                <div class="c-nav-dropdown-link c-btn-setting">
                  <span class="material-icons c-btn-setting-icon">
                    settings
                  </span>
                  <span class="c-btn-setting-txt" id="SettingButtonText"
                    >設定</span
                  >
                </div>
                <ul class="c-nav-dropdown c-nav-dropdown-setting">
                  <li>
                    <developBox>DEV MODE</developBox>
                  </li>
                  <li class="c-nav-dropdown-item">
                    <label class="c-form-label" for="language">
                      表示言語の設定
                    </label>
                    <select
                      name="language"
                      id="language"
                      class="c-form-select"
                      v-model="language"
                    >
                      <option value="ja">日本語</option>
                      <option value="en">English</option>
                      <option value="zh-hans">Chinese</option>
                      <option value="zh-hant">Chinese(Traditional)</option>
                    </select>
                  </li>
                  <li class="c-nav-dropdown-item">
                    <label class="c-form-label" for="runSpeed">
                      実行速度の設定
                    </label>
                    <select
                      name="runSpeed"
                      id="runSpeed"
                      class="c-form-select"
                      v-model="runSpeed"
                    >
                      <option value="-1">一瞬</option>
                      <option value="0">はやい</option>
                      <option value="10">おそい</option>
                      <option value="30">とてもおそい</option>
                    </select>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import developBox from "./DevelopBox";
import { loadCourse } from "../lib/problemLoader";
import jQuery from "jquery";

export default {
  name: "AppHeader",
  components: { developBox },
  props: ["isCourseSelect"],
  data() {
    return {
      levelList: [],
      runSpeed: 0,
      language: "ja",
      renderComponent: true,
      isClear: {},
      currentLevelID: "",
      courseName: "",
      organizationID: "",
      courseID: "",
    };
  },
  created() {
    this.organizationID = this.$route.params.organizationID || "demo";
    this.courseID = this.$route.params.courseID || "tutorial";
    this.currentLevelID = this.$route.params.levelID;

    if (this.isCourseSelect) {
      return;
    }

    const course = loadCourse(this.organizationID, this.courseID);

    this.courseName = course.courseName;

    for (let level of course.levelList) {
      this.levelList.push({
        levelID: level.levelID,
        levelNumber: level.levelNumber,
        levelName: level.levelName,
        firstProblemID: level.problemList[0]?.problemID,
        problemList: level.problemList,
      });
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const $ = jQuery;
      // グローバルナビゲーションの設定
      $(".c-nav-gnav > ul > li:has( > ul)").addClass("c-nav-dropdown-parent");
      $(".c-nav-gnav > ul > li > ul:not(:has(ul))").addClass(
        "c-nav-dropdown-child"
      );

      $(".l-header-container").before(
        '<button aria-label="メニューを開く" class="c-nav-mobile" ><span></span><span></span><span></span></button>'
      );
      $(".c-nav-gnav > ul > li").hover(function (e) {
        if ($(window).width() > 1024) {
          $(this).children("a").toggleClass("active");
          $(this)
            .children("ul")
            .stop(true, false)
            .toggleClass("c-nav-dropdown-display");
          e.preventDefault();
        }
      });
      $(".c-nav-dropdown-link").click(function (e) {
        if ($(window).width() <= 1023) {
          $(this).parent("li").toggleClass("c-nav-dropdown-close");
          $(this).next("ul.c-nav-dropdown").slideToggle(200);
          e.preventDefault();
        }
      });
      $(".c-nav-mobile").click(function (e) {
        $(".c-nav-gnav-list").toggleClass("c-nav-mobile-show");
        $(".c-nav-mobile").toggleClass("c-nav-mobile-close");
        e.preventDefault();
      });
      this.updateClearFlag();
    },
    clickProblemChange(nextLevel) {
      // ヘッダのレベル表示の、表示したままにする場所の更新
      this.currentLevelID = nextLevel;

      // コンポーネントをいったん消すことで、jqによって付与されたclassをリセットする
      // これによって、ステージ選択のナビゲーションを強引に閉じる
      // TODO:狙ったタグだけを除去することで初期化したい
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
        this.$nextTick(() => {
          this.init();
        });
      });

      this.updateClearFlag();
    },
    updateClearFlag() {
      if (this.isCourseSelect) {
        return;
      }

      let course = loadCourse(this.organizationID, this.courseID);
      for (const level of course.levelList) {
        for (const problemList of level.problemList) {
          this.isClear[problemList.problemID] =
            localStorage[problemList.problemID + "clear"] == 1;
        }
      }
    },
    backJump() {
      if (window.history.length > 2) {
        this.$router.go(-1);
      } else {
        this.$router.push({ path: `/` });
      }
    },
  },
  watch: {
    runSpeed: function (val) {
      localStorage["runSpeed"] = val;
      this.$emit("updateRunSpeed");
    },
    language: async function (val) {
      localStorage["language"] = val;
      this.$emit("updateLanguage");
    },
  },
};
</script>
