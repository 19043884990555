// テキスト
export const block_text = {
  kind: "block",
  type: "text",
  colour: "%{BKY_TEXTS_HUE}",
  fields: {
    TEXT: "Hello World",
  },
};
export const block_text_multiline = {
  kind: "block",
  type: "text_multiline",
  colour: "%{BKY_TEXTS_HUE}",
  fields: {
    TEXT: "Hello World123\nHello World456",
  },
};
export const block_text_join = {
  kind: "block",
  type: "text_join",
  colour: "%{BKY_TEXTS_HUE}",
  inputs: {
    ADD0: {
      shadow: {
        type: "text",
        fields: {
          TEXT: "Hello",
        },
      },
    },
    ADD1: {
      shadow: {
        type: "text",
        fields: {
          TEXT: "World",
        },
      },
    },
  },
};
export const block_text_length = {
  kind: "block",
  type: "text_length",
  colour: "%{BKY_TEXTS_HUE}",
  inputs: {
    VALUE: {
      shadow: {
        type: "text",
        fields: {
          TEXT: "Hello",
        },
      },
    },
  },
};

export const block_text_indexOf = {
  kind: "block",
  type: "text_indexOf",
  colour: "%{BKY_TEXTS_HUE}",
  inputs: {
    VALUE: {
      shadow: {
        type: "text",
        fields: {
          TEXT: "Hello World Hello World",
        },
      },
    },
    FIND: {
      shadow: {
        type: "text",
        fields: {
          TEXT: "World",
        },
      },
    },
  },
};

export const block_text_charAt = {
  kind: "block",
  type: "text_charAt",
  colour: "%{BKY_TEXTS_HUE}",
  inputs: {
    VALUE: {
      shadow: {
        type: "text",
        fields: {
          TEXT: "Hello World Hello World",
        },
      },
    },
  },
};

export const block_text_getSubstring = {
  kind: "block",
  type: "text_getSubstring",
  colour: "%{BKY_TEXTS_HUE}",
  inputs: {},
};

export const block_text_atoi = {
  kind: "block",
  type: "text_atoi",
  colour: "%{BKY_MATH_HUE}",
  inputs: {
    value: {
      shadow: {
        type: "text",
        fields: {
          TEXT: "A",
        },
      },
    },
  },
};

export const block_text_itoa = {
  kind: "block",
  type: "text_itoa",
  colour: "%{BKY_TEXTS_HUE}",
  inputs: {
    value: {
      shadow: {
        type: "math_number",
        fields: {
          NUM: 1,
        },
      },
    },
  },
};

export const category_text_fullset = {
  kind: "category",
  name: "%{BKY_CATEGORY_TEXT}",
  colour: "%{BKY_TEXTS_HUE}",
  contents: [
    block_text,
    block_text_multiline,
    block_text_join,
    block_text_length,
    block_text_indexOf,
    block_text_charAt,
    block_text_getSubstring,
    block_text_atoi,
    block_text_itoa,
  ],
};
