import q1 from "./calculation_1.json";
import q2 from "./calculation_2.json";
import q3 from "./calculation_3.json";
import q4 from "./calculation_4.json";
import q5 from "./calculation_5.json";
import q6 from "./calculation_6.json";
import q7 from "./calculation_7.json";
import q8 from "./calculation_8.json";
import q9 from "./calculation_9.json";
import q10 from "./calculation_10.json";
import q11 from "./calculation_11.json";
import q12 from "./calculation_12.json";
import q13 from "./calculation_13.json";

export default [q1, q2, q3, q4, q5, q6, q7, q8, q9, q10, q11, q12, q13];
