import blockly_tutorial from "../problems/blockly_tutorial/";

import get_and_calculation from "../problems/get_and_calculation/";

import variable_tutorial from "../problems/variable_tutorial/";

import reverse_array from "../problems/reverse_array.json";
import sandbox from "../problems/sandbox.json";
import question from "../problems/question.json";

import loop_tutorial_1 from "../problems/loop_tutorial_1.json";
import loop_tutorial_2 from "../problems/loop_tutorial_2.json";
import loop_tutorial_3 from "../problems/loop_tutorial_3.json";
import loop_tutorial_4 from "../problems/loop_tutorial_4.json";
import loop_tutorial_5 from "../problems/loop_tutorial_5.json";
import loop_tutorial_6 from "../problems/loop_tutorial_6.json";

import loop_calculation_1 from "../problems/loop_calculation_1.json";
import loop_calculation_2 from "../problems/loop_calculation_2.json";
import loop_calculation_3 from "../problems/loop_calculation_3.json";
import loop_calculation_4 from "../problems/loop_calculation_4.json";
import loop_calculation_5 from "../problems/loop_calculation_5.json";

import conditional_branch from "../problems/conditional_branch/";

import and_or from "../problems/and_or/";

import string_manipulation from "../problems/string_manipulation/";

import advanced_problems from "../problems/advanced_problems/";

import advanced_problems_2 from "../problems/advanced_problems/advanced_problems_2.json";
import advanced_problems_3 from "../problems/advanced_problems/advanced_problems_3.json";

import algorithm from "../problems/algorithm/";

import calculation from "../problems/calculation/";

import block_practice from "../problems/block_practice/";

import k_loop_tutorial_1 from "../problems/k_loop_tutorial_1.json";
import k_loop_tutorial_2 from "../problems/k_loop_tutorial_2.json";
import k_loop_tutorial_3 from "../problems/k_loop_tutorial_3.json";
import k_loop_tutorial_4 from "../problems/k_loop_tutorial_4.json";
import k_loop_tutorial_5 from "../problems/k_loop_tutorial_5.json";
import k_loop_tutorial_6 from "../problems/k_loop_tutorial_6.json";

import k_loop_calculation_1 from "../problems/k_loop_calculation_1.json";
import k_loop_calculation_2 from "../problems/k_loop_calculation_2.json";
import k_loop_calculation_3 from "../problems/k_loop_calculation_3.json";
import k_loop_calculation_4 from "../problems/k_loop_calculation_4.json";

import k_conditional_branch from "../problems/k_conditional_branch/";

import k_doc_sample from "../problems/k_doc_sample.json";
import k_sort_sample from "../problems/k_sort_sample.json";

import Newton_1 from "../problems/Newton_1.json";
import Newton_2 from "../problems/Newton_2.json";
import Newton_3 from "../problems/Newton_3.json";

import caesar_1 from "../problems/caesar/caesar_1.json";
import caesar_2 from "../problems/caesar/caesar_2.json";
import caesar_3 from "../problems/caesar/caesar_3.json";
import caesar_4 from "../problems/caesar/caesar_4.json";
import caesar_5 from "../problems/caesar/caesar_5.json";
import caesar_6 from "../problems/caesar/caesar_6.json";
import caesar_7 from "../problems/caesar/caesar_7.json";
import caesar_8 from "../problems/caesar/caesar_8.json";
import caesar_9 from "../problems/caesar/caesar_9.json";
import caesar_10 from "../problems/caesar/caesar_10.json";

import heron from "../problems/heron/";

import sim_and_or_3 from "../problems/sim_and_or_3.json";
import sim_and_or_4 from "../problems/sim_and_or_4.json";
import sim_and_or_6 from "../problems/sim_and_or_6.json";
import and_or_ex_1 from "../problems/and_or_ex_1.json";

import filter from "../problems/filter/";

import double_loop from "../problems/double_loop/";

import s_loop_tutorial_1 from "../problems/s_loop_tutorial_1.json";
import s_loop_tutorial_2 from "../problems/s_loop_tutorial_2.json";
import s_loop_tutorial_3 from "../problems/s_loop_tutorial_3.json";
import s_loop_tutorial_4 from "../problems/s_loop_tutorial_4.json";

import sim_k_loop_calculation_1 from "../problems/sim_k_loop_calculation_1.json";
import sim_k_loop_calculation_2 from "../problems/sim_k_loop_calculation_2.json";
import sim_k_loop_calculation_3 from "../problems/sim_k_loop_calculation_3.json";
import sim_k_loop_calculation_4 from "../problems/sim_k_loop_calculation_4.json";

import hit_and_blow from "../problems/hit_and_blow/";

import classify_1 from "../problems/classify_1.json";
import classify_2 from "../problems/classify_2.json";

import sim_blockly_tutorial_1 from "../problems/sim_blockly_tutorial_1.json";
import sim_blockly_tutorial_2 from "../problems/sim_blockly_tutorial_2.json";
import sim_blockly_tutorial_3 from "../problems/sim_blockly_tutorial_3.json";
import sim_blockly_tutorial_4 from "../problems/sim_blockly_tutorial_4.json";

import sim_get_and_calculation_1 from "../problems/sim_get_and_calculation_1.json";
import sim_get_and_calculation_2 from "../problems/sim_get_and_calculation_2.json";
import sim_get_and_calculation_3 from "../problems/sim_get_and_calculation_3.json";
import sim_get_and_calculation_4 from "../problems/sim_get_and_calculation_4.json";

import PnnCm_1 from "../problems/PnnCm_1.json";
import PnnCm_2 from "../problems/PnnCm_2.json";
import PnnCm_3 from "../problems/PnnCm_3.json";
import PnnCm_4 from "../problems/PnnCm_4.json";

import sim_string_manipulation from "../problems/sim_string_manipulation";

import sim_conditional_branch_1 from "../problems/sim_conditional_branch_1.json";
import sim_conditional_branch_2 from "../problems/sim_conditional_branch_2.json";

import sim_loop_tutorial_1 from "../problems/sim_loop_tutorial_1.json";
import sim_loop_tutorial_2 from "../problems/sim_loop_tutorial_2.json";

import sim_block_practice_1 from "../problems/sim_block_practice_1.json";
import sim_block_practice_2 from "../problems/sim_block_practice_2.json";


export const tutorialCourse = {
  courseName: "チュートリアルコース",
  courseID: "tutorial",
  levelList: [
    {
      levelID: "blockly_tutorial",
      levelNumber: 1,
      levelName: "チュートリアル",
      problemList: blockly_tutorial,
    },
    {
      levelID: "get_and_calculation",
      levelNumber: 2,
      levelName: "値の取得と計算",
      problemList: get_and_calculation,
    },
    {
      levelID: "variable_tutorial",
      levelNumber: 3,
      levelName: "変数入門",
      problemList: variable_tutorial,
    },
    {
      levelID: "loop_tutorial",
      levelNumber: 4,
      levelName: "ループ入門",
      problemList: [
        loop_tutorial_1,
        loop_tutorial_2,
        loop_tutorial_3,
        loop_tutorial_4,
        loop_tutorial_5,
        loop_tutorial_6,
      ],
    },
  ],
};

export const basicCourse = {
  courseName: "ベーシックコース",
  courseID: "basic",
  levelList: [
    {
      levelID: "loop_calculation",
      levelNumber: 1,
      levelName: "ループ応用",
      problemList: [
        loop_calculation_1,
        loop_calculation_2,
        loop_calculation_3,
        loop_calculation_4,
        loop_calculation_5,
      ],
    },
    {
      levelID: "conditional_branch",
      levelNumber: 2,
      levelName: "条件分岐",
      problemList: conditional_branch,
    },
    {
      levelID: "and_or",
      levelNumber: 3,
      levelName: "かつ、または",
      problemList: and_or,
    },
  ],
};

export const advancedCourse = {
  courseName: "アドバンスドコース",
  courseID: "advanced",
  levelList: [
    {
      levelID: "string_manipulation",
      levelNumber: 8,
      levelName: "文字列操作",
      problemList: string_manipulation,
    },
    {
      levelID: "advanced_problems",
      levelNumber: 9,
      levelName: "応用問題",
      problemList: advanced_problems,
    },
    {
      levelID: "algorithm",
      levelNumber: 10,
      levelName: "アルゴリズムを学ぶ",
      problemList: algorithm,
    },
    {
      levelID: "calculation",
      levelNumber: 11,
      levelName: "計算問題",
      problemList: calculation,
    },
    {
      levelID: "block_practice_1",
      levelNumber: 12,
      levelName: "ブロックの使い方を学ぶ",
      problemList: block_practice,
    },
    {
      levelID: "wip",
      levelNumber: "xx",
      levelName: "開発中",
      problemList: [reverse_array, sandbox, question],
    },
  ],
};

// 桐井製作所3回目講習 ここから
export const TutorialCourseK3 = {
  courseName: "チュートリアルコース",
  courseID: "tutorial",
  levelList: [
    {
      levelID: "blockly_tutorial",
      levelNumber: 1,
      levelName: "基本操作",
      problemList: blockly_tutorial,
    },
    {
      levelID: "get_and_calculation",
      levelNumber: 2,
      levelName: "値の取得と計算",
      problemList: get_and_calculation,
    },
  ],
};

export const BasicCourseK3 = {
  courseName: "ベーシックコース",
  courseID: "k_basic",
  levelList: [
    {
      levelID: "loop_tutorial",
      levelNumber: 1,
      levelName: "ループ入門",
      problemList: [
        k_loop_tutorial_1,
        k_loop_tutorial_2,
        k_loop_tutorial_3,
        k_loop_tutorial_4,
        k_loop_tutorial_5,
        k_loop_tutorial_6,
      ],
    },
    {
      levelID: "conditional_branch",
      levelNumber: 2,
      levelName: "条件分岐",
      problemList: k_conditional_branch,
    },
  ],
};

export const AdvancedCourseK3 = {
  courseName: "アドバンスドコース",
  courseID: "k_advanced",
  levelList: [
    {
      levelID: "loop_calculation",
      levelNumber: 1,
      levelName: "ループの応用",
      problemList: [
        k_loop_calculation_1,
        k_loop_calculation_2,
        k_loop_calculation_3,
        k_loop_calculation_4,
      ],
    },
    {
      levelID: "and_or",
      levelNumber: 2,
      levelName: "かつ、または",
      problemList: and_or,
    },
    {
      levelID: "advanced_problems",
      levelNumber: 3,
      levelName: "総合問題",
      problemList: [
        k_sort_sample,
        k_doc_sample,
        advanced_problems_2,
        advanced_problems_3,
        sandbox,
      ],
    },
  ],
};
// 桐井製作所3回目講習 ここまで

export const BasicCourseSwing = {
  courseName: "ベーシックコース",
  courseID: "s_basic",
  levelList: [
    {
      levelID: "loop_tutorial",
      levelNumber: 1,
      levelName: "ループ入門",
      problemList: [
        s_loop_tutorial_1,
        s_loop_tutorial_2,
        s_loop_tutorial_3,
        s_loop_tutorial_4,
      ],
    },
    {
      levelID: "conditional_branch",
      levelNumber: 2,
      levelName: "条件分岐",
      problemList: k_conditional_branch,
    },
  ],
};

export const develop = {
  courseName: "develop",
  courseID: "develop",
  levelList: [
    {
      levelID: "develop_1",
      levelNumber: 1,
      levelName: "開発用",
      problemList: [
        sandbox,
        // イスカスのバイトは以下に問題を追加して動作確認を行うこと
      ],
    },
    {
      levelID: "Newton",
      levelNumber: 2,
      levelName: "ニュートン法",
      problemList: [
        Newton_1,
        Newton_2,
        Newton_3,
      ],
    },
    {
      levelID: "caesar",
      levelNumber: 2,
      levelName: "シーザー暗号",
      problemList: [
        caesar_1,
        caesar_2,
        caesar_3,
        caesar_4,
        caesar_5,
        caesar_6,
        caesar_7,
        caesar_8,
        caesar_9,
        caesar_10
      ],
    },
    {
      levelID: "sim_and_or",
      levelNumber: 2,
      levelName: "かつ、またはの類似",
      problemList: [sim_and_or_3, sim_and_or_4, sim_and_or_6, and_or_ex_1],
    },
    {
      levelID: "heron",
      levelNumber: 2,
      levelName: "ヘロンの公式",
      problemList: heron,
    },
    {
      levelID: "filter",
      levelNumber: 2,
      levelName: "フィルター",
      problemList: filter,
    },
    {
      levelID: "double_loop",
      levelNumber: 2,
      levelName: "二重ループ",
      problemList: double_loop,
    },
    {
      levelID: "develop_2",
      levelNumber: 2,
      levelName: "ループの応用の類似問題",
      problemList: [
        sim_k_loop_calculation_1,
        sim_k_loop_calculation_2,
        sim_k_loop_calculation_3,
        sim_k_loop_calculation_4,
        sandbox,
      ],
    },
    {
      levelID: "hit_and_blow",
      levelNumber: 2,
      levelName: "hit&blow",
      problemList: hit_and_blow,
    },
    {
      levelID: "classify",
      levelNumber: 2,
      levelName: "分類",
      problemList: [
        classify_1,
        classify_2,
        sandbox,
      ],
    },
    {
      levelID: "blockly_tutorial_2",
      levelNumber: 1,
      levelName: "基本操作の類題",
      problemList: [
        sim_blockly_tutorial_1,
        sim_blockly_tutorial_2,
        sim_blockly_tutorial_3,
        sim_blockly_tutorial_4,
      ],
    },
    {
      levelID: "get_and_calculation_2",
      levelNumber: 2,
      levelName: "値の取得と計算の類似問題",
      problemList: [
        sim_get_and_calculation_1,
        sim_get_and_calculation_2,
        sim_get_and_calculation_3,
        sim_get_and_calculation_4,
        sandbox,
      ],
    },
    {
      levelID: "sim_block_practice",
      levelNumber: 2,
      levelName: "ブロックの使い方を学ぶの類似",
      problemList: [
        sim_block_practice_1,
        sim_block_practice_2,
        sandbox,
      ],
    },
    {
      levelID: "sim_conditional_branch",
      levelNumber: 2,
      levelName: "条件分岐の類題",
      problemList: [
        sim_conditional_branch_1,
        sim_conditional_branch_2,
        sandbox,
      ],
    },
    {
      levelID: "sim_loop_tutorial",
      levelNumber: 1,
      levelName: "ループ入門類題",
      problemList: [sim_loop_tutorial_1, sim_loop_tutorial_2, sandbox],
    },
    {
      levelID: "PnnCm",
      levelNumber: 2,
      levelName: "Pn,nCm",
      problemList: [PnnCm_1, PnnCm_2, PnnCm_3, PnnCm_4],
    },
    {
      levelID: "sim_string_manipulation",
      levelNumber: 2,
      levelName: "文字列操作の類似問題",
      problemList: sim_string_manipulation,
    },
  ],
};
