// 数学系ブロックの定義
export const block_math_number = {
  kind: "block",
  type: "math_number",
  fields: {
    NUM: 123,
  },
};
export const block_math_arithmetic = {
  kind: "block",
  type: "math_arithmetic",
  inputs: {
    A: {
      shadow: {
        type: "math_number",
        fields: {
          NUM: 1,
        },
      },
    },
    B: {
      shadow: {
        type: "math_number",
        fields: {
          NUM: 1,
        },
      },
    },
  },
};
export const block_math_single = {
  kind: "block",
  type: "math_single",
  inputs: {
    NUM: {
      shadow: {
        type: "math_number",
        fields: {
          NUM: 9,
        },
      },
    },
  },
};
export const block_math_trig = {
  kind: "block",
  type: "math_trig",
  inputs: {
    NUM: {
      shadow: {
        type: "math_number",
        fields: {
          NUM: 45,
        },
      },
    },
  },
};
export const block_math_constant = {
  kind: "block",
  type: "math_constant",
};
export const block_math_number_property = {
  kind: "block",
  type: "math_number_property",
  inputs: {
    NUMBER_TO_CHECK: {
      shadow: {
        type: "math_number",
        fields: {
          NUM: 2,
        },
      },
    },
  },
};
export const block_math_round = {
  kind: "block",
  type: "math_round",
  inputs: {
    NUM: {
      shadow: {
        type: "math_number",
        fields: {
          NUM: 3.141592,
        },
      },
    },
  },
};
export const block_math_on_list = {
  kind: "block",
  type: "math_on_list",
};
export const block_math_modulo = {
  kind: "block",
  type: "math_modulo",
  inputs: {
    DIVIDEND: {
      shadow: {
        type: "math_number",
        fields: {
          NUM: 64,
        },
      },
    },
    DIVISOR: {
      shadow: {
        type: "math_number",
        fields: {
          NUM: 10,
        },
      },
    },
  },
};
export const block_math_constrain = {
  kind: "block",
  type: "math_constrain",
  inputs: {
    VALUE: {
      shadow: {
        type: "math_number",
        fields: {
          NUM: 50,
        },
      },
    },
    LOW: {
      shadow: {
        type: "math_number",
        fields: {
          NUM: 1,
        },
      },
    },
    HIGH: {
      shadow: {
        type: "math_number",
        fields: {
          NUM: 100,
        },
      },
    },
  },
};
export const block_math_random_int = {
  kind: "block",
  type: "math_random_int",
  inputs: {
    FROM: {
      shadow: {
        type: "math_number",
        fields: {
          NUM: 1,
        },
      },
    },
    TO: {
      shadow: {
        type: "math_number",
        fields: {
          NUM: 100,
        },
      },
    },
  },
};
export const block_math_random_float = {
  kind: "block",
  type: "math_random_float",
};
export const block_math_atan2 = {
  kind: "block",
  type: "math_atan2",
  inputs: {
    X: {
      shadow: {
        type: "math_number",
        fields: {
          NUM: 1,
        },
      },
    },
    Y: {
      shadow: {
        type: "math_number",
        fields: {
          NUM: 1,
        },
      },
    },
  },
};

export const block_math_change = {
  kind: "block",
  type: "math_change",
  inputs: {
    DELTA: {
      shadow: {
        type: "math_number",
        fields: {
          NUM: 1,
        },
      },
    },
  },
};

export const category_math_fullset = {
  kind: "category",
  name: "%{BKY_CATEGORY_MATH}",
  colour: "%{BKY_MATH_HUE}",
  contents: [
    block_math_number,
    block_math_arithmetic,
    block_math_single,
    block_math_trig,
    block_math_constant,
    block_math_number_property,
    block_math_round,
    block_math_on_list,
    block_math_modulo,
    block_math_constrain,
    block_math_random_int,
    block_math_random_float,
    block_math_atan2,
    block_math_change,
  ],
};
