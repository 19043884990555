export const CategoryTranslation = {
  ja: {
    CATEGORY_LOGIC: "論理",
    CATEGORY_LOOP: "繰り返し",
    CATEGORY_MATH: "数学",
    CATEGORY_TEXT: "テキスト",
    CATEGORY_LIST: "リスト",
    CATEGORY_COLOUR: "色",
    CATEGORY_SPREADSHEET: "スプレッドシート",
    CATEGORY_VARIABLES: "変数",
    CATEGORY_FUNCTIONS: "関数",
  },
  en: {
    CATEGORY_LOGIC: "Logic",
    CATEGORY_LOOP: "Loops",
    CATEGORY_MATH: "Math",
    CATEGORY_TEXT: "Text",
    CATEGORY_LIST: "Lists",
    CATEGORY_COLOUR: "Colour",
    CATEGORY_SPREADSHEET: "SpreadSheet",
    CATEGORY_VARIABLES: "Variables",
    CATEGORY_FUNCTIONS: "Functions",
  },
  "zh-hans": {
    CATEGORY_LOGIC: "逻辑",
    CATEGORY_LOOP: "循环",
    CATEGORY_MATH: "数学",
    CATEGORY_TEXT: "文本",
    CATEGORY_LIST: "列表",
    CATEGORY_COLOUR: "颜色",
    CATEGORY_SPREADSHEET: "表",
    CATEGORY_VARIABLES: "变量",
    CATEGORY_FUNCTIONS: "函数",
  },
  "zh-hant": {
    CATEGORY_LOGIC: "邏輯",
    CATEGORY_LOOP: "迴圈",
    CATEGORY_MATH: "數學式",
    CATEGORY_TEXT: "文字",
    CATEGORY_LIST: "列表",
    CATEGORY_COLOUR: "顏色",
    CATEGORY_SPREADSHEET: "表",
    CATEGORY_VARIABLES: "變量",
    CATEGORY_FUNCTIONS: "流程",
  },
};
