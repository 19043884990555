import * as ToolBox from "../lib/blockSettings";

export const {
  category_logic_fullset,
  category_loop_fullset,
  category_spreadsheet_fullset,
  category_math_fullset,
  category_text_fullset,
} = ToolBox;

export function Generate(blockNameList = null) {
  if (!blockNameList?.length) {
    // null、空配列だったらフルセットを返す
    return ToolBox.Toolbox_category_fullset;
  } else if (blockNameList === "empty") {
    // "empty"だったら何も返さない（配置用のブロックがなくなる）
    return null;
  }

  // block_ で始まるローカル変数を全部引っ張ってくる
  let blockNameMap = {};
  for (let [objectName, obj] of Object.entries(ToolBox)) {
    if (objectName.startsWith("block_")) {
      blockNameMap[obj.type] = obj;
    }
  }

  let isCategory = blockNameList.some((e) => {
    return e.includes(">") || "VARIABLES" == e || "FUNCTIONS" == e;
  });

  if (isCategory) {
    // カテゴリのtoolbox

    // カテゴリ名と色のマップを作る
    let defaultColorMap = {};
    defaultColorMap.Logic = ToolBox.category_logic_fullset.colour;
    defaultColorMap.Loop = ToolBox.category_loop_fullset.colour;
    defaultColorMap.Math = ToolBox.category_math_fullset.colour;
    defaultColorMap.Text = ToolBox.category_logic_fullset.colour;
    defaultColorMap.Spreadsheet = ToolBox.category_logic_fullset.colour;

    // カテゴリ名のリプレイス（旧データ用）
    let defaultCategoryMap = {};
    defaultCategoryMap.Logic = "%{BKY_CATEGORY_LOGIC}";
    defaultCategoryMap.Loop = "%{BKY_CATEGORY_LOOP}";
    defaultCategoryMap.Math = "%{BKY_CATEGORY_MATH}";
    defaultCategoryMap.Text = "%{BKY_CATEGORY_TEXT}";
    defaultCategoryMap.Spreadsheet = "%{BKY_CATEGORY_SPREADSHEET}";

    let categoryBlockList = {};

    let variablesFlag = false;
    let functionsFlag = false;

    let categoryName = "";

    for (let blockName of blockNameList) {
      if (blockName == "VARIABLES") {
        variablesFlag = true;
        continue;
      }

      if (blockName == "FUNCTIONS") {
        functionsFlag = true;
        continue;
      }

      if (blockName.includes(">")) {
        [categoryName, blockName] = blockName.split(">");
      } else {
        categoryName = "Blocks";
      }

      if (!(categoryName in categoryBlockList)) {
        categoryBlockList[categoryName] = {
          kind: "category",
          name: defaultCategoryMap[categoryName] || categoryName,
          contents: [],
        };

        // いつものやつなら色設定を引き継ぐ
        let color = defaultColorMap[categoryName];
        if (color) {
          categoryBlockList[categoryName].colour = color;
        }
      }

      if (blockName in blockNameMap) {
        categoryBlockList[categoryName].contents.push(blockNameMap[blockName]);
      }
    }
    let toolbox = {
      kind: "categoryToolbox",
      contents: [],
    };

    for (let categoryName of Object.keys(categoryBlockList)) {
      toolbox.contents.push(categoryBlockList[categoryName]);
    }

    // カスタムカテゴリを追加
    if (variablesFlag) {
      toolbox.contents.push(ToolBox.category_variables);
    }
    if (functionsFlag) {
      toolbox.contents.push(ToolBox.category_functions);
    }

    return toolbox;
  } else {
    // べた書きのtoolbox
    let toolbox = {
      kind: "flyoutToolbox",
      contents: [],
    };

    for (let blockName of blockNameList) {
      if (blockName in blockNameMap) {
        toolbox.contents.push(blockNameMap[blockName]);
      }
    }
    return toolbox;
  }
}
