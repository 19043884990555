// テキスト
export const block_log = {
  kind: "block",
  type: "log",
  colour: "%{BKY_TEXTS_HUE}",
  fields: {
    TEXT: "Hello World",
  },
};

export const category_util_fullset = {
  kind: "category",
  name: "UTIL",
  colour: "%{BKY_TEXTS_HUE}",
  contents: [
    block_log,
  ],
};
