import { organizations } from "./organizations.js";

export function loadProblem(organizationID, courseID, levelID, problemID) {
  const organization = organizations[organizationID];
  if (typeof organization === "undefined") {
    return {};
  }

  const course = organization.courseList.find((c) => c.courseID == courseID);
  if (typeof course === "undefined") {
    return {};
  }
  const courseIndex = organization.courseList.findIndex(
    (c) => c.courseID == courseID
  );

  const level = course.levelList.find((l) => l.levelID == levelID);
  if (typeof level === "undefined") {
    return {};
  }
  const levelIndex = course.levelList.findIndex((l) => l.levelID == levelID);

  const problemList = level.problemList;
  if (typeof problemList === "undefined") {
    return {};
  }
  const problemIndex = problemList.findIndex((el) => el.problemID == problemID);

  let nextProblemID = problemList[problemIndex + 1]?.problemID;
  let nextLevelID = levelID;
  let nextCourceID = courseID;

  if (!nextProblemID) {
    console.log(course.levelList[levelIndex + 1]?.levelID);
    if (course.levelList[levelIndex + 1]?.levelID) {
      nextLevelID = course.levelList[levelIndex + 1].levelID;
      nextProblemID = course.levelList[levelIndex + 1].problemList[0].problemID;
      console.log("next1", nextCourceID, nextLevelID, nextProblemID);
    } else {
      if (organization.courseList[courseIndex + 1]?.courseID) {
        nextCourceID = organization.courseList[courseIndex + 1].courseID;
        nextLevelID =
          organization.courseList[courseIndex + 1].levelList[0].levelID;
        nextProblemID =
          organization.courseList[courseIndex + 1].levelList[0].problemList[0]
            .problemID;
        console.log("next2", nextCourceID, nextLevelID, nextProblemID);
      } else {
        nextProblemID = null;
        nextLevelID = null;
        nextCourceID = null;
        console.log("next3", nextCourceID, nextLevelID, nextProblemID);
      }
    }
  }

  return {
    problemList,
    problemIndex,
    problemData: problemList[problemIndex],
    course,
    level,
    nextProblemID,
    nextLevelID,
    nextCourceID,
  };
}

export function loadCourseList(organizationID) {
  const organization = organizations[organizationID];
  if (typeof organization === "undefined") {
    return {};
  }

  return organization.courseList;
}

export function loadCourse(organizationID, courseID) {
  const organization = organizations[organizationID];
  if (typeof organization === "undefined") {
    return {};
  }

  const course = organization.courseList.find((c) => c.courseID == courseID);
  if (typeof course === "undefined") {
    return {};
  }
  return course;
}
