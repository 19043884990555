import * as courses from "./courses.js";

export const organizations = {
  demo: {
    organizationName: "demo",
    AuthenticationRequired: false,
    courseList: [courses.tutorialCourse],
  },
  aidemy: {
    organizationName: "aidemy",
    AuthenticationRequired: true,
    courseList: [
      courses.tutorialCourse,
      courses.basicCourse,
      courses.advancedCourse,
    ],
  },
  kiriiseisakusho3: {
    organizationName: "kiriiseisakusho3",
    AuthenticationRequired: true,
    courseList: [
      courses.TutorialCourseK3,
      courses.BasicCourseK3,
      courses.AdvancedCourseK3,
    ],
  },
  swing: {
    organizationName: "swing",
    AuthenticationRequired: true,
    courseList: [
      courses.TutorialCourseK3,
      courses.BasicCourseSwing,
      courses.AdvancedCourseK3,
    ],
  },
  devcourse: {
    organizationName: "devcourse",
    AuthenticationRequired: true,
    courseList: [
      courses.develop,
      courses.TutorialCourseK3,
      courses.BasicCourseK3,
      courses.AdvancedCourseK3,
      courses.advancedCourse,
    ],
  },
};
