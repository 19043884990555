<template>
  <AppHeader
    @updateRunSpeed="updateRunSpeed"
    @updateLanguage="updateLanguage"
    ref="header"
  />
  <!-- メイン -->
  <main class="l-main">
    <nav class="c-breadcrumb">
      <div class="c-breadcrumb-inner">
        <h2 class="c-breadcrumb-title">
          <span class="c-breadcrumb-title-title"> {{ courseName }} > </span>
          <span class="c-breadcrumb-title-label">
            Lv.{{ currentLevel.levelNumber }}
          </span>
          <span class="c-breadcrumb-title-title">
            {{ currentLevel.levelName }}
          </span>
        </h2>
        <ul class="c-breadcrumb-list">
          <li
            class="c-breadcrumb-list-item"
            v-for="(problemData, index) in currentLevel.problemList"
            :key="problemData.problemID"
            v-bind:class="{ finished: isClear[problemData.problemID] }"
          >
            <template v-if="problemID != problemData.problemID">
              <router-link
                :to="`/qa/${$route.params.organizationID}/${$route.params.courseID}/${levelID}/${problemData.problemID}`"
              >
                <span
                  class="material-icons number"
                  v-if="isClear[problemData.problemID]"
                >
                  done
                </span>
                <span class="number" v-else>
                  {{ index + 1 }}
                </span>
                <span class="title">
                  {{ problemData.problemName }}
                </span>
              </router-link>
            </template>
            <template v-else>
              <span
                class="material-icons number"
                v-if="isClear[problemData.problemID]"
              >
                done
              </span>
              <span class="number" v-else>
                {{ index + 1 }}
              </span>
              <span class="title">
                {{ problemData.problemName }}
              </span>
            </template>
          </li>
        </ul>
      </div>
    </nav>
    <div class="c-col-container-fluid">
      <div class="c-col-row">
        <!-- 問題 -->
        <div class="c-col-md-4 c-col-12">
          <div class="l-main-result">
            <section class="l-main-question">
              <h3 class="l-main-question-title">問題</h3>
              <div class="l-main-question-box">
                <div class="l-main-question-more open">
                  <p v-html="description"></p>
                  <p class="l-main-question-note">{{ note }}</p>
                </div>
                <button
                  class="l-main-question-more-btn"
                  @click="toggleNoteDetail"
                >
                  <template v-if="noteDetail"> - 閉じる </template>
                  <template v-else> + もっと見る </template>
                </button>
              </div>
            </section>

            <div class="l-main-result-title-wrap">
              <h3 class="l-main-result-title">結果</h3>
              <button
                id="reset-sheet"
                class="c-btn-result"
                @click="initSpreadsheet"
                v-bind:disabled="isProgramRunning"
              >
                <span class="material-icons c-btn-result-icon"> refresh </span>
                <span class="c-btn-result-txt">スプレッドシートを初期化</span>
              </button>
            </div>
            <div id="spreadsheet" class="l-main-result-spreadsheet">
              <SpreadSheet
                ref="spreadsheet"
                :start="start"
                :goal="goal"
                mode="read"
              />
            </div>

            <div id="realtimeCodePreview" class="l-main-result-code">
              <h4 class="l-main-result-code-title">コードプレビュー</h4>
              <!-- PREは改行をそのまま表示するので、codeをネストして表示してはいけない -->
              <pre
                v-highlightjs
              ><code class="javascript">{{realtimeCodePreview}}</code></pre>
            </div>
          </div>
        </div>

        <!-- ブロック -->
        <div class="c-col-md-8 c-col-12">
          <section class="l-main-block">
            <div class="l-main-block-control">
              <div class="l-main-block-control-inner">
                <button
                  class="c-btn-submit c-btn-stop initializeButton"
                  @click="stopCode"
                  v-if="isProgramRunning"
                >
                  <span class="material-icons c-btn-submit-icon"> stop </span>
                  <span class="c-btn-submit-label"> プログラム停止 </span>
                </button>

                <button
                  id="js-modal-result-open"
                  class="c-btn-submit initializeButton"
                  @click="doCode"
                  v-else
                >
                  <span class="material-icons c-btn-submit-icon">
                    play_arrow
                  </span>
                  <span class="c-btn-submit-label"> プログラム実行 </span>
                </button>

                <button
                  class="c-btn-submit answer"
                  @click="showAnswer"
                  v-if="openAnswer"
                >
                  <span class="c-btn-submit-label"> 解答 </span>
                </button>
              </div>

              <div class="l-main-block-control-inner inline">
                <button
                  id="js-modal-help-open"
                  class="c-btn-help"
                  @click="showHelp"
                >
                  <span class="material-icons c-btn-help-icon"> help </span>
                  <span class="c-btn-help-label"> 操作方法 </span>
                </button>
                <button
                  class="c-btn-default c-btn-initialize initializeButton"
                  @click="loadInitialBlock"
                >
                  <span class="material-icons c-btn-default-icon">
                    refresh
                  </span>
                  <span class="c-btn-default-label">
                    ブロックを最初に戻す
                  </span>
                </button>
              </div>
            </div>
            <div class="l-main-block-area" id="blocklyDiv">
              <BlocklyBase
                ref="blocklyBase"
                @doCodeCallback="doCodeCallBack"
                @updateBlockCallback="updateBlockCallBack"
                :controlFunctions="controlFunctions"
                :problemID="problemID"
                :blockList="blockList"
                :initialBlock="initialBlock"
              />
            </div>
          </section>
        </div>
      </div>
    </div>
  </main>

  <AppDialog @next="goNextProblem" ref="dialog" />
  <AppHelp ref="help" :problemID="problemID" />
</template>

<script>
import BlocklyBase from "../components/BlocklyBase.vue";
import SpreadSheet from "../components/SpreadSheet.vue";
import AppDialog from "../components/AppDialog.vue";
import AppHelp from "../components/AppHelp.vue";
import AppHeader from "../components/AppHeader.vue";

import { loadProblem } from "../lib/problemLoader.js";

import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

import "../assets/style/style.css";

export default {
  name: "ProblemSpreadSheet",
  components: {
    SpreadSheet,
    BlocklyBase,
    AppHeader,
    AppDialog,
    AppHelp,
  },
  data() {
    return {
      problemID: "",
      levelID: "",
      nextProblemID: null,
      nextLevelID: null,
      nextCourceID: null,
      problemName: "",
      description: "",
      note: "",
      currentLevel: {},
      courseName: "",
      openAnswer: false,

      blockList: [],
      initialBlock: null,
      start: null,
      goal: null,
      controlFunctions: {},
      realtimeCodePreview: "",
      noteDetail: true,
      isProgramRunning: false,
      isClear: {},
    };
  },
  created: function () {
    console.log("created");
    this.init(
      this.$route.params.organizationID,
      this.$route.params.courseID,
      this.$route.params.levelID,
      this.$route.params.problemID
    );
  },
  mounted() {
    console.log("mounted");
    this.controlFunctions = {
      log: console.log,
      ...this.$refs.spreadsheet.getBlocklyExportFunctions(),
    };
  },
  beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate");
    this.init(
      to.params.organizationID,
      to.params.courseID,
      to.params.levelID,
      to.params.problemID
    );

    this.$nextTick(function () {
      // スプレッドシートを作り直す
      this.$refs.spreadsheet.initSpreadsheet();
      // Blocklyを作り直す
      this.$refs.blocklyBase.initBlockly();
      // ヘッダのクリアフラグの更新
      this.$refs.header.updateClearFlag();
    });

    next();
  },
  methods: {
    init(organizationID, courseID, levelID, problemID) {
      // TODO: 認証確認

      let {
        problemList,
        problemData,
        level,
        course,
        nextProblemID,
        nextLevelID,
        nextCourceID,
      } = loadProblem(organizationID, courseID, levelID, problemID);

      // TODO: リストが存在しない場合、ルートへ飛ばす、ルートから別のURLに飛ばす
      if (problemList == null) {
        this.$router.push({ path: `/` });
        return;
      }

      // ステージが存在しなかったら、ステージ１に戻る
      if (problemData == null) {
        let firstCourceID = course[0].courseID;
        let firstLevelID = course[0].levelList[0].levelID;
        let firstProblemID = course[0].levelList[0].problemList[0].problemID;
        this.$router.push({
          path: `/qa/${organizationID}/${firstCourceID}/${firstLevelID}/${firstProblemID}`,
        });
        return;
      }

      // 各種ステージデータの読み込み
      this.currentLevel = level;
      this.problemID = problemID;
      this.levelID = levelID;
      this.nextProblemID = nextProblemID;
      this.nextLevelID = nextLevelID;
      this.nextCourceID = nextCourceID;
      this.problemName = problemData.problemName;
      this.description = problemData.description;
      this.note = problemData.note;
      this.blockList = problemData.blockList;
      this.initialBlock = problemData.initialBlock;
      this.start = problemData.start;
      this.goal = problemData.goal;
      this.realtimeCodePreview = "";
      this.courseName = course.courseName;

      for (const problem of problemList) {
        this.isClear[problem.problemID] =
          localStorage[problem.problemID + "clear"] == 1;
      }

      this.openAnswer = false;
    },
    doCode() {
      this.isProgramRunning = true;
      // スプレッドシートの初期化
      this.$refs.spreadsheet.initSpreadsheet();
      this.$refs.blocklyBase.doCode();
    },
    stopCode() {
      this.isProgramRunning = false;
      this.$refs.blocklyBase.stopCode();
    },
    doCodeCallBack() {
      this.isProgramRunning = false;
      let success = this.$refs.spreadsheet.checkAnswer(this.goal);
      localStorage[this.problemID + "clear"] = success ? 1 : 0;
      this.isClear[this.problemID] = success ? 1 : 0;

      if (this.$refs.spreadsheet.isSandbox()) {
        console.log("sandbox now"); // サンドボックスの場合は正解/不正解ダイヤログを出さない
      } else if (!success) {
        this.openAnswer = true;
        // 不正解の時はToastを表示して強度を下げる
        setTimeout(() => {
          createToast(
            {
              title: "不正解",
              description: "ピンク色のセルの場所が誤っています",
            },
            {
              position: "top-left",
              timeout: 3000,
              showIcon: true,
              hideProgressBar: true,
              transition: "slide",
              showCloseButton: false,
            }
          );
        }, 1000);
      } else {
        setTimeout(() => {
          this.$refs.dialog.show(success);
        }, 1000);
      }
    },
    loadInitialBlock() {
      this.$refs.blocklyBase.loadInitialBlock();
    },
    updateBlockCallBack(code) {
      this.realtimeCodePreview = code;
    },
    goNextProblem() {
      console.log(
        "go next problem",
        this.nextCourceID,
        this.nextLevelID,
        this.nextProblemID
      );
      if (this.nextProblemID) {
        this.$router.push({
          path: `/qa/${this.$route.params.organizationID}/${this.nextCourceID}/${this.nextLevelID}/${this.nextProblemID}`,
        });
      }
    },
    showHelp() {
      this.$refs.help.show();
    },
    showAnswer() {
      this.$refs.help.showAnswer();
    },
    toggleNoteDetail() {
      this.noteDetail = !this.noteDetail;
      const content = document.querySelector(".l-main-question-more");
      if (this.noteDetail) {
        content.style.maxHeight = content.scrollHeight + "px";
        content.classList.add("open");
      } else {
        content.style.maxHeight = "50px";
        content.classList.remove("open");
      }
    },
    initSpreadsheet() {
      this.$refs.spreadsheet.initSpreadsheet();
    },
    updateLanguage() {
      this.$refs.blocklyBase.initBlockly();
    },
    updateRunSpeed() {
      this.$refs.blocklyBase.updateRunSpeed();
    },
  },
};
</script>

<style scoped>
code {
  white-space: pre;
  line-height: 1.2em;
}
.answer {
  font-size: smaller;
  margin-left: 8px;
}
</style>
