<template>
  <transition>
    <section id="js-modal-help" class="p-window" v-show="showModalWindow">
      <div class="p-window-bg js-modal-help-close" @click="close"></div>
      <div class="l-main-inner p-window-content help">
        <a
          class="p-window-close js-modal-help-close"
          href="javascript:void(0)"
          title="ヘルプウィンドウを閉じる"
          @click="close"
          >×</a
        >
        <div v-if="!answer">
          <h2 class="p-window-title">操作方法</h2>
          <div v-if="!showingGif">
            <div v-for="step in movies" :key="step">
              <span>{{ step.category }}</span>
              <div class="grid">
                <div
                  class="grid_item"
                  v-for="movie in step.gif"
                  :key="movie"
                  @click="showingGif = movie"
                >
                  {{ movie.title }}
                </div>
              </div>
            </div>
          </div>
          <div v-if="showingGif">
            <p>{{ showingGif.title }}</p>
            <button @click="showingGif = false">戻る</button>
            <p>{{ showingGif.note }}</p>
            <img class="movie" :src="showingGif.src" />
          </div>
        </div>
        <div v-else>
          <h2 class="p-window-title">解答</h2>
          <img class="answer" :src="answerImg" />
        </div>
      </div>
    </section>
  </transition>
</template>

<script>
export default {
  props: ["problemID"],
  data() {
    return {
      showModalWindow: false,
      showingGif: false,
      movies: [],
      answer: false,
    };
  },
  mounted() {
    this.movies = [
      {
        category: "step1",
        gif: [
          {
            title: "ブロックの配置",
            srcName: "place_block",
            note: "左側の灰色の領域からブロックをドラッグ＆ドロップします。",
          },
          {
            title: "値の変更",
            srcName: "change_value",
            note: "値を変えたい箇所をクリックし、値を入力します。数字が半角になっていることを確認してください。全角ではうまくいきません。",
          },
          {
            title: "ブロックの削除",
            srcName: "delete_block",
            note: "ブロックを左側の灰色の領域か右下のゴミ箱マークにドラッグ＆ドロップすると削除できます。ブロックの削除はBackspaceキー、Deleteキーでも行えます。",
          },
          {
            title: "ブロックの接続(縦)",
            srcName: "joint_block",
            note: "ブロックの凹凸を合わせるように置くと接続されます。",
          },
          {
            title: "ブロックの接続(横)",
            srcName: "right_joint",
            note: "ブロックの凹凸を合わせるように置くと接続されます。",
          },
          {
            title: "拡大・縮小",
            srcName: "scaling",
            note: "右下の+・-ボタンで拡大・縮小できます。拡大・縮小はマウスホイールでも行えます。",
          },
        ],
      },
      {
        category: "step2",
        gif: [
          {
            title: "ブロックの挿入",
            srcName: "nest",
            note: "ブロックを挿入したい箇所に、ブロックをドラッグ＆ドロップすると挿入できます。ブロック同士の凹凸を合わせるように置くのがコツです。",
          },
          {
            title: "カテゴリからのブロック配置",
            srcName: "from_category",
            note: "左側のカテゴリをクリックすると、それに属するブロックが展開されます。",
          },
          {
            title: "操作を取り消す",
            srcName: "undo",
            note: "右クリックを押して「取り消す」を選ぶか、ctrl+zキーを押すと操作を取り消せます。間違えてブロックを削除してしまった際などに使います。",
          },
          {
            title: "ブロックを最初に戻す",
            srcName: "reset_block",
            note: "ブロックを最初の配置に戻したい時は、「ブロックを最初に戻す」ボタンを押します。",
          },
          {
            title: "スプレッドシートの初期化",
            srcName: "reset_sheet",
            note: "スプレッドシートの数値を最初に戻したい時は、「スプレッドシートを初期化」ボタンを押します。",
          },
          {
            title: "ifブロックの設定",
            srcName: "if_gear",
            note: "歯車ボタンを押すと、「そうでなくもし」「そうでなければ」を追加できます。",
          },
        ],
      },
    ];
    this.movies.forEach((c) => {
      c.gif.forEach((e) => {
        e.src = require("../assets/movies/" + e.srcName + ".gif");
      });
    });
  },
  computed: {
    answerImg() {
      return require("../assets/answers/" + this.problemID + ".png");
    },
  },
  methods: {
    show() {
      this.showModalWindow = true;
      this.answer = false;
    },
    close() {
      this.showModalWindow = false;
      this.showingGif = "";
    },
    showAnswer() {
      this.showModalWindow = true;
      this.answer = true;
    },
  },
};
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s;
}

.v-enter,
.v-leave-to {
  opacity: 0;
}

.p-window-content {
  width: 60%;
}

.movie {
  width: 100%;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
}

.grid_item {
  padding: 7px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.5);
}

.p-window-title {
  font-size: 150%;
}
</style>
