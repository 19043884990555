<template>
  <div>
    <!--
    <select name="language" id="language" v-model="language">
      <option value="ja">Language:日本語</option>
      <option value="en">Language:English</option>
      <option value="zh-hans">Language:Chinese</option>
      <option value="zh-hant">Language:Chinese(Transition)</option>
    </select>

    <select name="runSpeed" id="runSpeed" v-model="runSpeed">
      <option value="-1">実行速度：一瞬</option>
      <option value="0">実行速度：はやい</option>
      <option value="10">実行速度：おそい</option>
      <option value="30">実行速度：とてもおそい</option>
    </select>

    <button
      class="initializeButton"
      v-on:click="loadInitialBlock"
      :disabled="codeIsRunning"
    >
      初期化
    </button>

    <button class="runButton" v-on:click="stopCode" v-if="codeIsRunning">
      プログラム停止
    </button>
    <button class="runButton" v-on:click="doCode" v-else>
      ▶️プログラム実行
    </button>
    -->
    <div id="blocklyDiv"></div>

    <!--
    <template v-if="realtimeCodePreview.length > 0">
      <h3>コードプレビュー</h3>
      <div>
        <pre id="realtimeCodePreview">
          <p v-html="realtimeCodePreview"></p>
        </pre>
      </div>
    </template>

    <template v-if="Object.keys(variableWatch).length > 0">
      <h3>変数ウォッチ</h3>
      <div class="variableWatchTableOuterDiv">
        <table id="variableWatchTable" rules="all">
          <tr v-for="(value, name) in variableWatch" :key="name">
            <td>
              <div>{{ name }}</div>
            </td>
            <td>
              <div>{{ value }}</div>
            </td>
          </tr>
        </table>
      </div>
    </template>
    -->
  </div>
</template>

<script>
import Blockly from "blockly";
import { utils as BlocklyUtil } from "blockly";
import { javascriptGenerator } from "blockly/javascript";
import Interpreter from "js-interpreter";

import * as LangJA from "blockly/msg/ja";
import * as LangEN from "blockly/msg/en";
import * as LangZHS from "blockly/msg/zh-hans";
import * as LangZHT from "blockly/msg/zh-hant";
import { OriginalBlockTranslation } from "../lib/blocks";
import { CategoryTranslation } from "../lib/categoryTranslation";

import * as ToolBox from "../lib/toolboxGenerator";

const LANGUAGE_MAP = {
  ja: LangJA,
  en: LangEN,
  "zh-hans": LangZHS,
  "zh-hant": LangZHT,
};

export default {
  data() {
    return {
      realtimeCodePreview: "",
      workspace: null,
      runSpeed: 0,
      language: "ja",
      codeIsRunning: false,
      codeTimeoutId: null,
      variableWatch: {},
    };
  },
  props: ["problemID", "controlFunctions", "blockList", "initialBlock"],
  mounted: function () {
    this.initBlockly();
  },
  methods: {
    initBlockly: function () {
      // コードが実行中なら停止
      this.stopCode();
      // コードプレビューとウォッチ式を初期化
      this.variableWatch = {};
      this.realtimeCodePreview = "";

      // Blocklyのノードの下にオブジェクトがあるなら全削除
      let injectRootNode = document.getElementById("blocklyDiv");
      if (injectRootNode.hasChildNodes()) {
        // remove all child node
        while (injectRootNode.lastElementChild) {
          injectRootNode.removeChild(injectRootNode.lastElementChild);
        }
      }

      // 言語設定を読み取り
      let savedLanguage = localStorage.getItem("language");
      if (savedLanguage && savedLanguage in LANGUAGE_MAP) {
        this.language = savedLanguage;
      } else {
        this.language = "ja";
      }
      Blockly.setLocale(LANGUAGE_MAP[this.language]);

      // カテゴリの翻訳
      if (this.language && this.language in CategoryTranslation) {
        Blockly.setLocale(CategoryTranslation[this.language]);
      } else {
        Blockly.setLocale(CategoryTranslation["en"]);
      }

      // オリジナルブロックの言語設定
      if (this.language && this.language in OriginalBlockTranslation) {
        Blockly.setLocale(OriginalBlockTranslation[this.language]);
      } else {
        Blockly.setLocale(OriginalBlockTranslation["en"]);
      }

      // ツールボックスを生成する
      let toolbox = ToolBox.Generate(this.blockList);

      // ブロックリーの初期化
      this.workspace = Blockly.inject("blocklyDiv", {
        toolbox: toolbox,
        zoom: {
          controls: true,
          wheel: true,
          startScale: 1.0,
          maxScale: 3,
          minScale: 0.3,
          scaleSpeed: 1.2,
          pinch: true,
        },
        trashcan: true,
      });

      // ブロックが変更されたイベントを引っ掛けて、自動保存する
      // TODO: セーブするイベントを絞る
      this.workspace.addChangeListener((event) => {
        if (event.recordUndo) {
          console.log(event);
          let blocklyDom = Blockly.Xml.workspaceToDom(this.workspace);
          let blocklyDomText = Blockly.Xml.domToText(blocklyDom);
          localStorage.setItem(this.problemID, blocklyDomText);

          let code = javascriptGenerator.workspaceToCode(this.workspace);
          this.realtimeCodePreview = code;
          this.$emit("updateBlockCallback", code);
        }
      });

      // 保存されているXMLをロードする
      let savedDomText = localStorage.getItem(this.problemID);
      if (savedDomText) {
        let blocklyXml = BlocklyUtil.xml.textToDom(savedDomText);
        // memo this.workspace は Vueのオブジェクトで、これを渡すとバグるので、 Blockly.mainWorkspaceを渡す
        Blockly.Xml.domToWorkspace(blocklyXml, Blockly.getMainWorkspace());
      } else {
        // 過去のブロックが保存されていないなら、初期ブロックを読み込む
        this.loadInitialBlock();
      }

      // 実行速度を読み取る
      let savedDomSpeed = localStorage.getItem("runSpeed");
      this.runSpeed = savedDomSpeed | 0;

      // ハイライトブロックを予約語にして変数に選択できなくする
      javascriptGenerator.addReservedWords("highlightBlock");
    },
    loadInitialBlock: function () {
      // initialBlockが与えられていたら初期化する
      if (this.initialBlock && this.initialBlock != "") {
        Blockly.getMainWorkspace().clear();

        let blocklyXml = BlocklyUtil.xml.textToDom(this.initialBlock);
        Blockly.Xml.domToWorkspace(blocklyXml, Blockly.getMainWorkspace());
      } else {
        Blockly.getMainWorkspace().clear();
      }
    },
    doCode: function () {
      // ウォッチ式をクリア
      this.variableWatch = {};

      // ステップ実行ならばハイライト用のコードを付与
      if (this.runSpeed >= 0) {
        javascriptGenerator.STATEMENT_PREFIX = "highlightBlock(%1);\n";
      }
      let generatedCode = javascriptGenerator.workspaceToCode(this.workspace);
      javascriptGenerator.STATEMENT_PREFIX = "";

      // コード実行中の実行を禁止する
      if (this.codeIsRunning) {
        return;
      }
      this.codeIsRunning = true;

      // スコープをいい感じにする
      let _this = this;

      // インタプリタの定義、独自関数を親の空間で実行させる
      let myInterpreter = new Interpreter(
        generatedCode,
        (interpreter, scope) => {
          // set_value, get_value等の独自関数を定義
          // スプレッドシート以外が接続される場合はcontrolFunctionsに専用のコールバックを入れる
          for (const [functionName, func] of Object.entries(
            _this.controlFunctions
          )) {
            interpreter.setProperty(
              scope,
              functionName,
              interpreter.createNativeFunction((...args) => {
                return func(...args);
              })
            );
          }

          // ハイライト用のコールバックコードを定義、ステップ実行時のみ有効
          if (_this.runSpeed >= 0) {
            const functionNameList = Object.keys(_this.controlFunctions);
            const ignoreGlobalObject = [
              "window",
              "self",
              "highlightBlock",
              ...functionNameList,
            ];

            interpreter.setProperty(
              scope,
              "highlightBlock",
              interpreter.createNativeFunction((id) => {
                id = id ? id.toString() : "";
                _this.workspace.highlightBlock(id);

                // 有効なグローバル変数を引っ掛ける、もうちょっと綺麗に書きたい
                let globalObjects = {
                  ...myInterpreter.globalObject.properties,
                };
                ignoreGlobalObject.forEach((e) => delete globalObjects[e]);

                // 変数ウォッチの更新
                _this.variableWatch = globalObjects;
              })
            );
          }
        }
      );

      // Stringなどのネイティブ関数がコケるので、グローバルスコープを渡す
      Interpreter.nativeGlobal = window;

      if (this.runSpeed >= 0) {
        // ステップ実行を定義
        let stepCounter = 0;
        let nextStep = function () {
          stepCounter++;
          if (stepCounter > 1000000) {
            // TODO: 無限ループアラート
            return;
          }
          if (_this.codeIsRunning == false) {
            // ページ遷移か、強制停止させられた
            return;
          }

          if (myInterpreter.step()) {
            if (_this.runSpeed >= 0) {
              // 次のステップ実行の予約
              _this.codeTimeoutId = setTimeout(nextStep, _this.runSpeed);
            } else {
              // ステップ実行中に、実行速度が一瞬に変更されたら残りを一気に実行
              myInterpreter.run();
              _this.codeIsRunning = false;
              // ハイライトを削除
              _this.workspace.highlightBlock("");
              // 結果を評価する
              _this.$emit("doCodeCallback");
            }
          } else {
            // 終了したので、評価する
            _this.codeIsRunning = false;
            // ハイライトを削除
            _this.workspace.highlightBlock("");
            // 結果を評価する
            _this.$emit("doCodeCallback");
          }
        };
        nextStep();
      } else {
        // 通常実行
        myInterpreter.run();
        _this.codeIsRunning = false;

        // 結果を評価する
        this.$emit("doCodeCallback");
      }
    },
    stopCode: function () {
      // スクリプトが実行中なら停止させる
      console.log("stop code", this.codeIsRunning, this.codeTimeoutId);
      if (this.codeIsRunning) {
        if (this.codeTimeoutId) {
          clearTimeout(this.codeTimeoutId);
        }
        this.codeIsRunning = false;
      }
      if (this.workspace) {
        this.workspace.highlightBlock("");
      }
    },
    updateRunSpeed() {
      this.runSpeed = localStorage["runSpeed"];
    },
  },
  unmounted: function () {
    this.stopCode();
  },
};
</script>
<style></style>
