<template>
  <AppHeader
    @updateRunSpeed="updateRunSpeed"
    @updateLanguage="updateLanguage"
    :isCourseSelect="true"
  />
  <!-- メイン -->
  <main class="l-main">
    <nav class="c-breadcrumb">
      <div class="c-breadcrumb-inner">
        <h2 class="c-breadcrumb-title">
          <span class="c-breadcrumb-title-label">
            Lv.{{ currentLevelID.levelNumber }} </span
          >1
          <span class="c-breadcrumb-title-title">
            {{ currentLevelID.levelName }}
          </span>
        </h2>
        <ul class="c-breadcrumb-list">
          <li
            class="c-breadcrumb-list-item"
            v-for="(problemList, index) in currentLevelID.problemList"
            :key="problemList.problemID"
            v-bind:class="{ finished: isClear[problemList.problemID] }"
          >
            <template v-if="problemID != problemList.problemID">
              <router-link :to="`/qa/${levelID}/${problemList.problemID}`">
                <span
                  class="material-icons number"
                  v-if="isClear[problemList.problemID]"
                >
                  done
                </span>
                <span class="number" v-else>
                  {{ index + 1 }}
                </span>
                <span class="title">
                  {{ problemList.problemName }}
                </span>
              </router-link>
            </template>
            <template v-else>
              <span
                class="material-icons number"
                v-if="isClear[problemList.problemID]"
              >
                done
              </span>
              <span class="number" v-else>
                {{ index + 1 }}
              </span>
              <span class="title">
                {{ problemList.problemName }}
              </span>
            </template>
          </li>
        </ul>
      </div>
    </nav>
    <div class="c-col-container-fluid">
      <div class="c-col-row">
        <!-- 問題 -->
        <div class="c-col-md-4 c-col-12">
          <div class="l-main-result">
            <section class="l-main-question">
              <h3 class="l-main-question-title">問題</h3>
              <div class="l-main-question-box">
                <div class="l-main-question-more open">
                  <p v-html="description"></p>
                  <p class="l-main-question-note">{{ note }}</p>
                </div>
                <button
                  class="l-main-question-more-btn"
                  @click="toggleNoteDetail"
                >
                  <template v-if="noteDetail"> - 閉じる </template>
                  <template v-else> + もっと見る </template>
                </button>
              </div>
            </section>

            <div class="l-main-result-title-wrap">
              <h3 class="l-main-result-title">結果</h3>
              <button
                id="reset-sheet"
                class="c-btn-result"
                @click="initSpreadsheet"
                v-bind:disabled="isProgramRunning"
              >
                <span class="material-icons c-btn-result-icon"> delete </span>
                <span class="c-btn-result-txt">スプレッドシートを初期化</span>
              </button>
            </div>
            <div id="spreadsheet" class="l-main-result-spreadsheet">
              <SpreadSheet
                ref="spreadsheet"
                :start="start"
                :goal="goal"
                mode="edit"
              />
            </div>

            <div id="realtimeCodePreview" class="l-main-result-code">
              <h4 class="l-main-result-code-title">コードプレビュー</h4>
              <!-- PREは改行をそのまま表示するので、codeをネストして表示してはいけない -->
              <pre
                v-highlightjs
              ><code class="javascript">{{realtimeCodePreview}}</code></pre>
            </div>
          </div>
        </div>

        <!-- ブロック -->
        <div class="c-col-md-4 c-col-12">
          <section class="l-main-block">
            <div class="l-main-block-control">
              <div class="l-main-block-control-inner">
                <button
                  class="c-btn-submit c-btn-stop initializeButton"
                  @click="stopCode"
                  v-if="isProgramRunning"
                >
                  <span class="material-icons c-btn-submit-icon"> stop </span>
                  <span class="c-btn-submit-label"> プログラム停止 </span>
                </button>

                <button
                  id="js-modal-result-open"
                  class="c-btn-submit initializeButton"
                  @click="doCode"
                  v-else
                >
                  <span class="material-icons c-btn-submit-icon">
                    play_arrow
                  </span>
                  <span class="c-btn-submit-label"> プログラム実行 </span>
                </button>
              </div>

              <div class="l-main-block-control-inner">
                <button
                  id="js-modal-help-open"
                  class="c-btn-help"
                  @click="showHelp"
                >
                  <span class="material-icons c-btn-help-icon"> help </span>
                  <span class="c-btn-help-label"> ヘルプ </span>
                </button>
                <button
                  class="c-btn-default c-btn-initialize initializeButton"
                  @click="loadInitialBlock"
                >
                  <span class="material-icons c-btn-default-icon">
                    delete
                  </span>
                  <span class="c-btn-default-label"> 初期化 </span>
                </button>
              </div>
            </div>
            <div class="l-main-block-area" id="blocklyDiv">
              <BlocklyBase
                ref="blocklyBase"
                @doCodeCallback="doCodeCallBack"
                @updateBlockCallback="updateBlockCallBack"
                :controlFunctions="controlFunctions"
                :problemID="stageEditorID"
                :blockList="blockList"
                :initialBlock="initialBlock"
              />
            </div>
          </section>
        </div>

        <!-- ステージエディタのための構成要素 -->
        <div class="c-col-md-4 c-col-12">
          <input v-model="problemID" placeholder="ステージID" />
          <input v-model="problemName" placeholder="表示ステージ名" />
          <br />
          <textarea
            v-model="description"
            placeholder="問題文をここに入力"
          ></textarea>
          <textarea v-model="note" placeholder="noteをここに入力"></textarea>
          <br />
          <button v-on:click="exportProblem">export</button>
          <button v-on:click="saveInitialBlock">
            Blockly: 初期ブロックの保存
          </button>
          <button v-on:click="saveUseBlock">Blockly: 利用ブロックの保存</button>
          <button v-on:click="saveStart">スプシ: 初期状態の保存</button>
          <button v-on:click="saveGoal">スプシ: ゴール状態の保存</button>
          <h3>start</h3>
          <div>{{ start }}</div>
          <h3>goal</h3>
          <div>{{ goal }}</div>
          <h3>利用可能なブロックリスト</h3>
          <div>{{ blockList }}</div>
          <h3>初期ブロックXML</h3>
          <div>{{ initialBlock }}</div>
          <h3>現在のブロックのXML</h3>
          <div>{{ blockXML }}</div>
        </div>
      </div>
    </div>
  </main>

  <AppDialog @next="goNextProblem" ref="dialog" />
  <AppHelp ref="help" />
</template>

<script>
import BlocklyBase from "../components/BlocklyBase.vue";
import SpreadSheet from "../components/SpreadSheet.vue";
import AppDialog from "../components/AppDialog.vue";
import AppHelp from "../components/AppHelp.vue";
import AppHeader from "../components/AppHeader.vue";

import * as ToolBox from "../lib/toolboxGenerator";

import "../assets/style/style.css";

export default {
  name: "StageEditor",
  components: {
    SpreadSheet,
    BlocklyBase,
    AppHeader,
    AppDialog,
    AppHelp,
  },
  data() {
    return {
      stageEditorID: "__STAGE_EDITOR__",
      problemID: "dummyStageId",
      levelID: "",
      nextProblemID: "",
      problemName: "",
      description: "",
      note: "",
      currentLevelID: {},
      blockList: [],
      initialBlock: null,
      start: [[]],
      goal: [[]],
      controlFunctions: {},
      realtimeCodePreview: "",
      noteDetail: true,
      isProgramRunning: false,
      isClear: {},
    };
  },
  created: function () {
    console.log("created");
    this.init(this.$route.params.levelID, this.$route.params.problemID);
  },
  mounted() {
    console.log("mounted");
    let log = (d) => {
      console.log(d);
      return d;
    };
    this.controlFunctions = {
      log: log,
      ...this.$refs.spreadsheet.getBlocklyExportFunctions(),
    };
  },
  beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate");
    this.init();

    this.$nextTick(function () {
      // スプレッドシートを作り直す
      this.$refs.spreadsheet.initSpreadsheet();
      // Blocklyを作り直す
      this.$refs.blocklyBase.initBlockly();
    });

    next();
  },
  methods: {
    init() {},

    doCode() {
      this.isProgramRunning = true;
      // スプレッドシートの初期化
      this.$refs.spreadsheet.initSpreadsheet();
      this.$refs.blocklyBase.doCode();
    },
    stopCode() {
      this.isProgramRunning = false;
      this.$refs.blocklyBase.stopCode();
    },
    doCodeCallBack() {
      this.isProgramRunning = false;
    },
    loadInitialBlock() {
      this.$refs.blocklyBase.loadInitialBlock();
    },
    updateBlockCallBack(code) {
      this.realtimeCodePreview = code;
      this.blockXML = localStorage[this.stageEditorID];
    },
    goNextProblem() {
      console.log("go next stage");
      if (this.nextProblemID) {
        this.$router.push({
          path: `/qa/${this.levelID}/${this.nextProblemID}`,
        });
      }
    },
    showHelp() {
      this.$refs.help.show();
    },
    toggleNoteDetail() {
      this.noteDetail = !this.noteDetail;
      const content = document.querySelector(".l-main-question-more");
      if (this.noteDetail) {
        content.style.maxHeight = content.scrollHeight + "px";
        content.classList.add("open");
      } else {
        content.style.maxHeight = "50px";
        content.classList.remove("open");
      }
    },
    initSpreadsheet() {
      this.$refs.spreadsheet.initSpreadsheet();
    },
    updateLanguage() {
      this.$refs.blocklyBase.initBlockly();
    },
    updateRunSpeed() {
      this.$refs.blocklyBase.updateRunSpeed();
    },

    // ココからステージエディタ用の関数
    getBlockList() {
      let savedDomText = localStorage.getItem(this.stageEditorID);
      if (!savedDomText) {
        return;
      }
      let blockList = [];
      // MEMO:DOMを舐めるのがだるいので正規表現でいい感じにする
      let isVariableBlock = false;
      let isFunctionBlock = false;

      let matched = savedDomText.matchAll(/<block type="(.+?)"/g);
      for (let item of matched) {
        let blockName = item[1];
        if (!blockList.includes(blockName)) {
          blockList.push(blockName);
        }
        if (blockName.includes("variables")) {
          isVariableBlock = true;
        }
        if (blockName.includes("procedures")) {
          isFunctionBlock = true;
        }
      }

      console.log(ToolBox);

      // カテゴリをいい感じにする
      const categoryList = [
        ToolBox.category_logic_fullset,
        ToolBox.category_loop_fullset,
        ToolBox.category_math_fullset,
        ToolBox.category_text_fullset,
        ToolBox.category_spreadsheet_fullset,
      ];

      blockList = blockList.map((name) => {
        console.log(categoryList);
        for (let category of categoryList) {
          for (let blockContents of category.contents) {
            if (blockContents.type == name) {
              console.log(category.name + ">" + name);
              return category.name + ">" + name;
            }
          }
        }
        // カテゴリに含まれないブロックはnullを返す
        return null;
      });

      blockList = blockList.filter((x) => x != null);

      if (isVariableBlock) {
        blockList.push("VARIABLES");
      }

      if (isFunctionBlock) {
        blockList.push("FUNCTIONS");
      }

      return blockList;
    },
    getSpreadsheets() {
      let ssData = [];
      let ss = this.$refs.spreadsheet.spreadsheet;
      for (let x = 0; x < ss.data.cols.len; x++) {
        let lineData = [];
        for (let y = 0; y < ss.data.rows.len; y++) {
          let val = ss.cell(y, x).text;

          if (typeof val === "number" && val === 0) {
            lineData.push(null);
            continue;
          }
          if (typeof val === "string" && !isNaN(Number(val))) {
            lineData.push(Number(val));
          } else {
            lineData.push(val);
          }
        }
        // 後ろ側にnullが入ってたら捨てる
        while (lineData.length > 0 && lineData[lineData.length - 1] == null) {
          lineData.pop();
        }

        ssData.push(lineData);
      }
      return ssData;
    },
    saveStart() {
      this.start = this.getSpreadsheets();
    },
    saveGoal() {
      this.goal = this.getSpreadsheets();
    },

    updateSpreadsheet(data) {
      let ss = this.$refs.spreadsheet.spreadsheet;
      let c_row = 0;
      let c_col = 0;
      1;
      data.forEach(function (cols) {
        c_row = 0;
        cols.forEach(function (j) {
          console.log(j);
          ss.cellText(c_row, c_col, j);
          if (j === 0) {
            ss.cellText(c_row, c_col, "0");
          }
          c_row += 1;
        });
        c_col += 1;
      });
    },
    loadStart() {
      this.updateSpreadsheet(this.start);
    },
    loadGoal() {
      this.updateSpreadsheet(this.goal);
    },
    exportProblem() {
      this.blockList = this.getBlockList();

      const problemList = {
        problemID: this.problemID,
        problemName: this.problemName,
        description: this.description,
        note: this.note,
        blockList: this.blockList,
        start: this.start,
        goal: this.goal,
        initialBlock: this.initialBlock,
      };
      const stageDataText = JSON.stringify(problemList, null, "  ");
      console.log(stageDataText);
      const blob = new Blob([stageDataText], { type: "text/plain" });
      const link = document.createElement("a");

      let date = new Date();
      date.setTime(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
      let strDate = date.toISOString().replace("T", " ").substr(0, 19);

      let filename = `problem_${strDate}.json`;
      if (this.problemID.length) {
        filename = `${this.problemID}_${strDate}.json`;
      }

      link.download = filename;
      link.href = URL.createObjectURL(blob);
      link.click();
      URL.revokeObjectURL(link.href);
    },
    saveInitialBlock() {
      this.initialBlock = this.blockXML;
    },
    saveUseBlock() {
      this.blockList = this.getBlockList();
      console.log(this.blockList);
    },
  },
};
</script>

<style>
code {
  white-space: pre;
  line-height: 1.2em;
}
</style>
