// ループ系ブロックの定義
export const block_controls_repeat_ext = {
  kind: "block",
  type: "controls_repeat_ext",
  inputs: {
    TIMES: {
      shadow: {
        type: "math_number",
        fields: {
          NUM: 10,
        },
      },
    },
  },
};
export const block_controls_whileUntil = {
  kind: "block",
  type: "controls_whileUntil",
};
export const block_controls_for = {
  kind: "block",
  type: "controls_for",
  inputs: {
    FROM: {
      shadow: {
        type: "math_number",
        fields: {
          NUM: 1,
        },
      },
    },
    TO: {
      shadow: {
        type: "math_number",
        fields: {
          NUM: 10,
        },
      },
    },
    BY: {
      shadow: {
        type: "math_number",
        fields: {
          NUM: 1,
        },
      },
    },
  },
};
export const block_controls_forEach = {
  kind: "block",
  type: "controls_forEach",
};
export const block_controls_flow_statements = {
  kind: "block",
  type: "controls_flow_statements",
};

export const category_loop_fullset = {
  kind: "category",
  name: "%{BKY_CATEGORY_LOOP}",
  colour: "%{BKY_LOOPS_HUE}",
  contents: [
    block_controls_repeat_ext,
    block_controls_whileUntil,
    block_controls_for,
    block_controls_forEach,
    block_controls_flow_statements,
  ],
};
