<template>
  <AppHeader :isCourseSelect="true" />
  <section class="l-main-index">
    <div class="c-col-container">
      <div class="c-col-row">
        <template v-for="course in courseList" :key="course.courseID">
          <div class="c-col-12">
            <h2 class="c-heading-primary">
              {{ course.courseName }}
            </h2>
          </div>

          <div
            class="c-col-12"
            v-for="level in course.levelList"
            :key="level.levelID"
          >
            <div>
              <h3 id="lv1" class="c-heading-secondary">
                <span class="c-nav-gnav-list-item-label">
                  Lv.{{ level.levelNumber }}
                </span>
                <span class="c-nav-gnav-list-item-title">
                  {{ level.levelName }}
                </span>
              </h3>
            </div>
            <div class="c-col-row">
              <div
                class="c-col-md-4 c-col-12"
                v-for="problem in level.problemList"
                :key="problem.problemID"
              >
                <!-- 完了ステージには finished クラスを追加 -->
                <router-link
                  class="c-btn-stage"
                  :to="`/qa/${$route.params.organizationID}/${course.courseID}/${level.levelID}/${problem.problemID}`"
                  v-bind:class="{ finished: isClear[problem.problemID] }"
                >
                  <span class="c-btn-stage-label">
                    {{ problem.problemName }}
                  </span>
                </router-link>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>

    <footer class="l-footer">
      <div class="c-col-container l-footer-container">
        <div class="c-col-row">
          <div class="c-col-12 c-col-md-8">
            <nav class="l-footer-nav">
              <ul class="l-footer-nav-list">
                <li class="l-footer-nav-list-item">
                  <!--<a href="../terms/"> 利用規約 </a>-->
                </li>
                <li class="l-footer-nav-list-item">
                  <!--<a href="#"> プライバシーポリシー </a>-->
                </li>
                <li class="l-footer-nav-list-item">
                  <a href="https://nextint.co.jp/"> 運営会社 </a>
                </li>
              </ul>
            </nav>
          </div>

          <div class="c-col-12 c-col-md-4">
            <p class="l-footer-copyright">
              <small>&copy; NextInt. 2022</small>
            </p>
          </div>
        </div>
      </div>
    </footer>
  </section>
</template>

<script>
import AppHeader from "../components/AppHeader.vue";
import { loadCourseList } from "../lib/problemLoader.js";

export default {
  name: "StageSelect",
  components: { AppHeader },
  data() {
    return {
      courseList: [],
      isClear: [],
    };
  },
  created() {
    // クリアフラグの生成

    this.courseList = loadCourseList(this.$route.params.organizationID);
    for (const course of this.courseList) {
      for (const level of course.levelList) {
        for (const problemList of level.problemList) {
          this.isClear[problemList.problemID] =
            localStorage[problemList.problemID + "clear"] == 1;
        }
      }
    }
  },
};
</script>
<style>
.q_region {
  margin-top: 1em;
  margin-left: 5em;
  margin-right: 5em;
}
.q_link {
  margin-left: 0.5em;
  margin-right: 0.5em;
}
</style>
