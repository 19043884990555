// スプレッドシート系
export const block_set_value = {
  kind: "block",
  type: "set_value",
  colour: 310,
  inputs: {
    col: {
      shadow: {
        type: "text",
        fields: {
          TEXT: "A",
        },
      },
    },
    row: {
      shadow: {
        type: "math_number",
        fields: {
          NUM: 1,
        },
      },
    },
    value: {
      shadow: {
        type: "math_number",
        fields: {
          NUM: 42,
        },
      },
    },
  },
};
export const block_set_value_s = {
  kind: "block",
  type: "set_value_s",
  colour: 310,
  inputs: {
    value: {
      shadow: {
        type: "math_number",
        fields: {
          NUM: 42,
        },
      },
    },
  },
};
export const block_get_value = {
  kind: "block",
  type: "get_value",
  colour: 310,
  inputs: {
    col: {
      shadow: {
        type: "text",
        fields: {
          TEXT: "A",
        },
      },
    },
    row: {
      shadow: {
        type: "math_number",
        fields: {
          NUM: 1,
        },
      },
    },
  },
};
export const block_get_value_s = {
  kind: "block",
  type: "get_value_s",
  colour: 310,
};
export const block_get_line_count = {
  kind: "block",
  type: "get_line_count",
  colour: 310,
  inputs: {
    col: {
      shadow: {
        type: "text",
        fields: {
          TEXT: "A",
        },
      },
    },
  },
};
export const category_spreadsheet_fullset = {
  kind: "category",
  name: "%{BKY_CATEGORY_SPREADSHEET}",
  colour: "310",
  contents: [
    block_set_value,
    block_set_value_s,
    block_get_value,
    block_get_value_s,
    block_get_line_count,
  ],
};
