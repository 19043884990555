export default {
    AtoI: function (value) {
      if(!(typeof value === "string")) return null;
      const UPPER_PATTERN = /^[A-Z]$/;
      const LOWER_PATTERN = /^[a-z]$/;
      let cc = value.charCodeAt(0);
      
      if(UPPER_PATTERN.test(value) == true) return (cc - "A".charCodeAt(0) + 1);
      if(LOWER_PATTERN.test(value) == true) return (cc - "a".charCodeAt(0) + 1);
      return null;
    },
    ItoA: function (value) {
      let v = parseInt(value);
      if(!Number.isInteger(v) || v < 1 || 26 < v) return null;
      
      return String.fromCharCode(v + "A".charCodeAt(0) - 1);
    },
};
