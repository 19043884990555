<template>
  <transition>
    <section id="js-modal-result" class="p-window" v-show="showModalWindow">
      <div class="p-window-bg js-modal-result-close"></div>
      <div class="l-main-inner p-window-content">
        <h2 class="p-window-head" v-if="success">
          <span class="p-window-head-icon material-icons ok">
            check_circle
          </span>
          <span class="p-window-head-txt"> 正解 </span>
        </h2>
        <h2 class="p-window-head" v-if="!success">
          <span class="p-window-head-icon material-icons ng"> cancel </span>
          <span class="p-window-head-txt"> 不正解 </span>
        </h2>
        <p class="p-window-content-message" v-if="!success">
          ※ここに改善のヒントやコメントが入ります。この文章はダミーです。※ここに改善のヒントやコメントが入ります。この文章はダミーです。
        </p>
        <div class="p-window-content-footer">
          <a
            href="javascript:void(0)"
            class="p-window-content-btn-close js-modal-result-close"
            @click="close"
          >
            閉じる
          </a>
          <a
            href="javascript:void(0)"
            class="p-window-content-btn js-modal-result-next"
            v-show="success"
            @click="next"
          >
            次の問題へ進む
          </a>
        </div>
      </div>
    </section>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      showModalWindow: false,
      success: false,
    };
  },
  methods: {
    show: function (success) {
      this.success = success;
      this.showModalWindow = true;
    },
    close: function () {
      this.showModalWindow = false;
    },
    next: function () {
      // ダブルクリック対策
      if (this.showModalWindow) {
        this.showModalWindow = false;
        this.$emit("next");
      }
    },
  },
};
</script>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s;
}

.v-enter,
.v-leave-to {
  opacity: 0;
}
</style>
