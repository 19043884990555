<template>
  <slot v-if="developMode"></slot>
</template>

<script>
export default {
  data() {
    return {
      developMode: false,
    };
  },
  methods: {},
  created() {
    let developMode = localStorage.getItem("SheetCampDevMode");
    if (developMode) {
      this.developMode = true;
    }
  },
};
</script>

<style></style>
