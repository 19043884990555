import q1 from "./get_and_calculation_1.json";
import q2 from "./get_and_calculation_2.json";
import q3 from "./get_and_calculation_3.json";
import q4 from "./get_and_calculation_4.json";
import q5 from "./get_and_calculation_5.json";
import q6 from "./get_and_calculation_6.json";
import q7 from "./get_and_calculation_7.json";
import q8 from "./get_and_calculation_8.json";

export default [q1, q2, q3, q4, q5, q6, q7, q8];
