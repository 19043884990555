import { createRouter, createWebHashHistory } from "vue-router";
import ProblemSpreadSheet from "../views/ProblemSpreadSheet.vue";
import StageEditor from "../views/StageEditor.vue";
import StageSelect from "../views/StageSelect.vue";

const routes = [
  {
    path: "/",
    redirect: "/qa/demo/tutorial/blockly_tutorial/blockly_tutorial_1",
  },
  {
    path: "/qa/:organizationID/:courseID/:levelID/:problemID",
    name: "",
    component: ProblemSpreadSheet,
  },
  {
    path: "/se",
    name: "StageEditor",
    component: StageEditor,
  },
  {
    path: "/problemselect/:organizationID",
    name: "ProblemSelect",
    component: StageSelect,
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
