export * from "./logic.js";
export * from "./logic.js";
export * from "./loop.js";
export * from "./math.js";
export * from "./spreadsheet.js";
export * from "./text.js";
export * from "./util.js";

import { category_logic_fullset } from "./logic.js";
import { category_loop_fullset } from "./loop.js";
import { category_math_fullset } from "./math.js";
import { category_spreadsheet_fullset } from "./spreadsheet.js";
import { category_text_fullset } from "./text.js";
import { category_util_fullset } from "./util.js";

// 変数系
export const block_variables_get = {
  kind: "block",
  type: "variables_get",
  colour: "%{BKY_VARIABLES_HUE}",
};
export const block_variables_set = {
  kind: "block",
  type: "variables_set",
  colour: "%{BKY_VARIABLES_HUE}",
};
export const block_variables_get_dynamic = {
  kind: "block",
  type: "variables_get_dynamic",
  colour: "%{BKY_VARIABLES_HUE}",
};
export const block_variables_set_dynamic = {
  kind: "block",
  type: "variables_set_dynamic",
  colour: "%{BKY_VARIABLES_HUE}",
};
export const category_variables = {
  kind: "category",
  name: "%{BKY_CATEGORY_VARIABLES}",
  colour: "%{BKY_VARIABLES_HUE}",
  custom: "VARIABLE",
};

// 関数

export const category_functions = {
  kind: "category",
  name: "%{BKY_CATEGORY_FUNCTIONS}",
  colour: "%{BKY_VARIABLES_HUE}",
  custom: "PROCEDURE",
};

export const Toolbox_category_fullset = {
  kind: "categoryToolbox",
  contents: [
    category_logic_fullset,
    category_loop_fullset,
    category_math_fullset,
    category_text_fullset,
    category_spreadsheet_fullset,
    category_variables,
    category_functions,
    category_util_fullset,
  ],
};
