<template>
  <!--<button @click="initSpreadsheet">リセット</button>-->
  <div id="spreadsheet"></div>
</template>
<script>
import Spreadsheet from "x-data-spreadsheet";
import blockFunctions from "../lib/blockFunctions.js";

export default {
  props: ["start", "goal", "mode"],
  data() {
    return {
      spreadsheet: null,
      lastAccessCol: -1,
      lastAccessRow: -1,
      typeInformation: {}, // 型情報を保存する
    };
  },
  mounted: function () {
    // スプレッドシートのタグを強引に削除する
    let injectRootNode = document.getElementById("spreadsheet");
    if (injectRootNode.hasChildNodes()) {
      while (injectRootNode.lastElementChild) {
        injectRootNode.removeChild(injectRootNode.lastElementChild);
      }
    }

    if (this.spreadsheet == null) {
      // スプレッドシートの初期化オプション
      const ss_option = {
        mode: this.mode,
        showToolbar: false,
        showContextmenu: false,
        showBottomBar: false,
        row: {
          len: 20,
        },
        col: {
          len: 10,
          width: 80,
        },
        view: {
          height: function () {
            const spreadsheetNode = document.getElementById("spreadsheet");
            return spreadsheetNode.offsetHeight - 3;
          },
          width: function () {
            const spreadsheetNode = document.getElementById("spreadsheet");
            return spreadsheetNode.offsetWidth - 3;
          },
        },
      };
      this.spreadsheet = new Spreadsheet("#spreadsheet", ss_option);

      const bgPattern = [
        { bgcolor: "#ffffff" }, // 白
        { bgcolor: "#ffcccc" }, // 不正解
        { bgcolor: "#ccffcc" }, // 正解
        { bgcolor: "#ffffff" }, // dummy
      ];

      const borderPattern = [
        {}, // no frame
        {
          // read
          border: {
            left: ["inside", "#32cd32"],
            right: ["inside", "#32cd32"],
            top: ["inside", "#32cd32"],
            bottom: ["inside", "#32cd32"],
          },
        },
        {
          // write
          border: {
            left: ["inside", "#ffa500"],
            right: ["inside", "#ffa500"],
            top: ["inside", "#ffa500"],
            bottom: ["inside", "#ffa500"],
          },
        },
        {}, //dummy
      ];

      let stylePattern = [];
      for (let border of borderPattern) {
        for (let bg of bgPattern) {
          stylePattern.push({ ...bg, ...border });
        }
      }

      // 背景色の情報をセットする
      this.spreadsheet.loadData([
        {
          styles: stylePattern,
        },
      ]);
    }
    this.initSpreadsheet();
  },
  methods: {
    getTypeInfo: function (col, row) {
      if (this.typeInformation[col]) {
        return this.typeInformation[col][row] || null;
      }
      return null;
    },
    setTypeInfo: function (col, row, val) {
      this.typeInformation[col] ||= {};
      this.typeInformation[col][row] = typeof val;
      if (val == null) {
        // nullの場合"object"になってしまうので、分かるようにnullを入れる
        this.typeInformation[col][row] = null;
      }
    },
    getStartGoalVal: function (col, row) {
      let startVal = null;
      let goalVal = null;

      if (this.start[col] != undefined) {
        if (this.start[col][row] != undefined) {
          startVal = this.start[col][row];
        }
      }

      if (this.goal[col] != undefined) {
        if (this.goal[col][row] != undefined) {
          goalVal = this.goal[col][row];
        }
      }
      return [startVal, goalVal];
    },
    removeLastAccessFrame(newCol, newRow) {
      if (this.lastAccessCol != -1 && this.lastAccessRow != -1) {
        // 3,4bitを落とす
        this.spreadsheet.datas[0].getCell(
          this.lastAccessRow - 1,
          this.lastAccessCol - 1
        ).style &= ~12;
      }
      this.lastAccessCol = newCol;
      this.lastAccessRow = newRow;
    },
    setValue: function (col, row, val, reRender = true) {
      const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      let c = col;

      if (!Number.isInteger(parseInt(col))) {
        c = alphabet.indexOf(col.toUpperCase()) + 1;
      }

      this.setValueByMethod(c, row, val);

      // 入力データに合わせて背景の色を変える
      // TODO: 強引に書き換えないで、もっといいやり方を探す
      const red = 1;
      const green = 2;
      let cellColor = 8; // 書込みなので4ビット目を立てる

      let [_startVal, goalVal] = this.getStartGoalVal(c - 1, row - 1);
      if (goalVal == val) {
        // 正解
        cellColor |= green;
      } else {
        // 不正解
        cellColor |= red;
      }
      this.spreadsheet.datas[0].getCell(row - 1, c - 1).style = cellColor;
      this.removeLastAccessFrame(c, row);

      // レンダリング
      if (reRender) {
        this.spreadsheet.reRender();
      }
    },
    getValue: function (col, row, reRender = true) {
      const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      let c = col;
      if (!Number.isInteger(parseInt(col))) {
        c = alphabet.indexOf(col.toUpperCase()) + 1;
      }
      let cell = this.spreadsheet.cell(row - 1, c - 1);
      let type = this.getTypeInfo(row - 1, c - 1);

      if (!cell) {
        // TODO: raise some error
        return null;
      }

      // readなので3bit目を立てる
      cell.style |= 4;
      this.removeLastAccessFrame(c, row);
      if (reRender) {
        this.spreadsheet.reRender();
      }

      // 型情報を復元する
      switch (type) {
        case "number":
          // 浮動小数点の可能性があるので、parseFloatで返す
          return parseFloat(cell.text);
        case "string":
          return cell.text;
        case null:
          // 未初期化の箇所を読み取っているので、数値型でゼロを返す
          return 0;
        default:
          // TODO: raise some error
          return cell.text;
      }
    },
    getLineCount: function (col) {
      const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      let c = col;
      if (!Number.isInteger(parseInt(col))) {
        c = alphabet.indexOf(col.toUpperCase()) + 1;
      }
      // 後ろからループを回して、非ゼロの箇所を探す
      for (let row = this.spreadsheet.data.rows.len - 1; row >= 0; row--) {
        let cell = this.spreadsheet.cell(row, c - 1);
        // 数値のゼロ（空欄）だったらパス
        if (cell && Number.isInteger(cell.text) && cell.text == 0) {
          continue;
        } else {
          return row + 1;
        }
      }
      return 0;
    },
    initSpreadsheet: function () {
      this.resetSpreadsheet();

      let nums = this.start;
      let c_row = 0;
      let c_col = 0;
      let _this = this;

      nums.forEach(function (cols) {
        c_row = 0;
        cols.forEach(function (j) {
          _this.setValueByMethod(c_col + 1, c_row + 1, j);
          c_row += 1;
        });
        c_col += 1;
      });

      this.spreadsheet.reRender();
    },
    resetSpreadsheet: function () {
      for (let row = 0; row < this.spreadsheet.data.rows.len; row++) {
        for (let col = 0; col < this.spreadsheet.data.cols.len; col++) {
          this.setValueByMethod(col + 1, row + 1, null);
          // 背景色を白に変更
          this.spreadsheet.datas[0].getCell(row, col).style = 0;
        }
      }
      this.spreadsheet.reRender();
    },
    setValueByMethod(col, row, val) {
      // 型情報を保存する
      this.setTypeInfo(row - 1, col - 1, val);

      if (val == null) {
        // nullのときは数値型で0を書き込んで消す
        this.spreadsheet.cellText(row - 1, col - 1, 0);
      } else {
        // 数値型で0を書き込むと消えるので、すべて文字列型にする
        this.spreadsheet.cellText(row - 1, col - 1, val.toString());
      }
    },
    checkAnswer: function (goal) {
      let nums = goal;
      let c_col = 0;
      let c_row = 0;
      let success = true;
      let _this = this;

      //答え合わせ（二重ループ）
      nums.forEach(function (cols) {
        c_row = 0;
        cols.forEach(function (j) {
          if (
            !_this.spreadsheet.cell(c_row, c_col) ||
            _this.spreadsheet.cell(c_row, c_col).text != j
          ) {
            // 背景色を赤に変更
            _this.spreadsheet.datas[0].getCell(c_row, c_col).style = 1;
            success = false;
          }
          c_row += 1;
        });
        c_col += 1;
      });

      this.spreadsheet.reRender();
      return success;
    },
    getBlocklyExportFunctions: function () {
      return Object.assign({
        getValue: this.getValue,
        setValue: this.setValue,
        getLineCount: this.getLineCount,
      }, blockFunctions);
    },
    isSandbox: function () {
      return this.start.length === 0 && this.goal.length === 0;
    },
  },
};
</script>
